import {Component, Input, OnInit} from '@angular/core';
import {DatePipe, NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from '@angular/common';
import {ResumeThemeModel} from '../../common/theme/resume-theme.model';
import {ResumeHasProjectOutDto} from '../../common/resume-section-model/project.model';
import {ResumeIconModel} from '../../common/resume-icon.model';
import {DateFormatter} from '../../../../common/util/date.util';
import {RESUME_SECTION_TYPE_DISPLAY} from '../../common/resume-section.model';
import {ATS_LAYOUT_TYPE, LAYOUT_TYPE} from '../../common/layout-selection.model';
import {LinkService} from '../../../../common/util/link.service';
import {SelectThemeUtil} from "../../common/select-theme.util";
import {ResumeStore} from "../../../ai-resume/common/store/resume.store";
import {ResumeOutDto, ResumeSectionDataOutDto} from "../../common/resume.model";
import {Subscription} from "rxjs";

@Component({
    selector: 'tal-r-project',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NgStyle,
        TitleCasePipe,
        NgClass,
        DatePipe
    ],
    templateUrl: './r-project.component.html',
    styleUrl: './r-project.component.scss'
})
export class RProjectComponent implements OnInit {
    @Input() resumeSectionDataOutDto: ResumeSectionDataOutDto;
    resumeOutDto?: ResumeOutDto;
    resumeTheme?: ResumeThemeModel;
    resumeHasProjectOutDtos: ResumeHasProjectOutDto[];

    ResumeIconModel = ResumeIconModel;
    DateFormatter = DateFormatter;
    RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
    protected readonly LAYOUT_TYPE = LAYOUT_TYPE;
    protected readonly ATS_LAYOUT_TYPE = ATS_LAYOUT_TYPE;
    private subscription = new Subscription();

    constructor(private resumeStore: ResumeStore,
                private linkService: LinkService) {
    }

    ngOnInit(): void {
        this.subscribeToResumeStore();
    }

    openUrlInNewTab(projectLink: any): void {
        this.linkService.openLinkInNewTab(projectLink);
    }

    private subscribeToResumeStore(): void {
        this.subscription.add(this.resumeStore.updatedResumeOutDto
            .subscribe(resumeOutDto => {
                if (resumeOutDto) {
                    this.resumeOutDto = resumeOutDto;
                    this.resumeHasProjectOutDtos = resumeOutDto?.projects;
                    this.setResumeTheme();
                }
            }));
    }

    private setResumeTheme(): void {
        this.resumeTheme = SelectThemeUtil.setResumeTheme(this.resumeOutDto.atsLayout === ATS_LAYOUT_TYPE.CLASSIC, this.resumeOutDto?.layoutType, this.resumeOutDto?.theme);
    }
}
