import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LinkService {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

    openLinkInNewTab(link: string): void {
        // Add protocol if missing
        if (!/^http[s]?:\/\//.test(link)) {
            link = 'http://' + link;
        }

        if (isPlatformBrowser(this.platformId)) {
            window.open(link, '_blank');
        }
    }
}
