import {Component, OnDestroy, OnInit} from '@angular/core';
import {PublicApiResumeService} from './service/public-api-resume.service';
import {ResumeOutDto, ResumeSectionDataOutDto} from './common/resume.model';
import {ATS_LAYOUT_TYPE, LAYOUT_TYPE} from './common/layout-selection.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTE} from '../../common/route-constants/route';
import {MetaTagService} from '../../service/meta-tag.service';
import {ResumeStore} from '../ai-resume/common/store/resume.store';
import {NgComponentOutlet, NgStyle, NgTemplateOutlet} from "@angular/common";
import {RResumeBrandingComponent} from "./component/r-resume-branding/r-resume-branding.component";
import {SelectThemeUtil} from "./common/select-theme.util";
import {ResumeThemeModel} from "./common/theme/resume-theme.model";
import {RESUME_SECTION_TYPE} from "./common/resume-section.model";
import {RAchievementComponent} from "./component/r-achievement/r-achievement.component";
import {RCertificateComponent} from "./component/r-certificate/r-certificate.component";
import {REducationComponent} from "./component/r-education/r-education.component";
import {RHobbyComponent} from "./component/r-hobby/r-hobby.component";
import {RSummaryComponent} from "./component/r-summary/r-summary.component";
import {RSkillComponent} from "./component/r-skill/r-skill.component";
import {RReferenceComponent} from "./component/r-reference/r-reference.component";
import {RLanguageComponent} from "./component/r-language/r-language.component";
import {RProjectComponent} from "./component/r-project/r-project.component";
import {RWorkExperienceComponent} from "./component/r-work-experience/r-work-experience.component";
import {RPersonalInformationComponent} from "./component/r-personal-information/r-personal-information.component";
import {RSocialLinkComponent} from "./component/r-social-link/r-social-link.component";
import {Subscription} from "rxjs";

@Component({
    selector: 'tal-resume',
    standalone: true,
    imports: [
        NgTemplateOutlet, NgComponentOutlet, NgStyle, RResumeBrandingComponent
    ],
    templateUrl: './resume.component.html',
    styleUrl: './resume.component.scss'
})

export class ResumeComponent implements OnInit, OnDestroy {
    resumeOutDto: ResumeOutDto;
    LAYOUT_TYPE = LAYOUT_TYPE;
    loading = true;
    isCandidateProfile = false;
    resumeTheme: ResumeThemeModel;
    leftColumnResumeSectionDataOutDtoWithComponents: ResumeSectionDataOutDtoWithComponent[] = [];
    rightColumnResumeSectionDataOutDtoWithComponents: ResumeSectionDataOutDtoWithComponent[] = [];

    protected readonly ATS_LAYOUT_TYPE = ATS_LAYOUT_TYPE;

    private subscription = new Subscription();

    constructor(private resumeService: PublicApiResumeService,
                private router: Router,
                private route: ActivatedRoute,
                private resumeStore: ResumeStore,
                private metaTagService: MetaTagService) {
        if (this.router.url.startsWith(`/${ROUTE.CANDIDATE}`)) {
            this.isCandidateProfile = true;
        }
    }

    ngOnInit(): void {
        this.getResumeData();
        this.subscribeToResumeStore();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private getResumeData(): void {
        if (this.isCandidateProfile) {
            this.setResumeOutDtoFromCandidateId();
        } else {
            this.setResumeOutDtoFromResumeId();
        }
    }

    private setResumeOutDtoFromCandidateId(): void {
        let candidateId = this.route.snapshot.params[ROUTE.CANDIDATE_ID];
        this.resumeService.findResumeByCandidateId(candidateId).subscribe(publicApiTalentMinOutDto => {
                this.loading = false;
                this.resumeOutDto = publicApiTalentMinOutDto?.resumeOutDto;
                this.metaTagService.addResumeTags(this.resumeOutDto);
                this.resumeStore.publishAndGetUpdatedResume(this.resumeOutDto);
                this.setResumeTheme();
            }
        );
    }

    private setResumeOutDtoFromResumeId(): void {
        let resumeId = this.route.snapshot.params[ROUTE.RESUME_ID];
        let universityId = this.route.snapshot.params[ROUTE.UNIVERSITY_ID];
        this.resumeService.findPublicResume(resumeId, universityId).subscribe((resumeOutDto: ResumeOutDto) => {
                this.loading = false;
                this.resumeOutDto = resumeOutDto;
                this.metaTagService.addResumeTags(this.resumeOutDto);
                this.resumeStore.publishAndGetUpdatedResume(this.resumeOutDto);
                resumeOutDto?.layoutType === LAYOUT_TYPE.ONE_COLUMN ? this.handleOneColumn(resumeOutDto) : this.handleTwoColumn(resumeOutDto);
                this.setResumeTheme();
            },
            (error) => {
                this.router.navigate([ROUTE.PAGE_NOT_FOUND]);
            }
        );
    }

    private handleOneColumn(resumeOutDto: ResumeOutDto): void {
        this.leftColumnResumeSectionDataOutDtoWithComponents = [];
        this.rightColumnResumeSectionDataOutDtoWithComponents = [];
        resumeOutDto.resumeSectionDataOutDtos
            .sort((previousSectionDataOutDto, nextSectionDataOutDto) => previousSectionDataOutDto.rowIndex - nextSectionDataOutDto.rowIndex)
            .forEach(resumeSectionDataOutDto => {
                this.leftColumnResumeSectionDataOutDtoWithComponents.push(this.createResumeSectionDataOutDtoWithComponent(resumeSectionDataOutDto));
            });
    }

    private handleTwoColumn(resumeOutDto: ResumeOutDto): void {
        this.leftColumnResumeSectionDataOutDtoWithComponents = [];
        this.rightColumnResumeSectionDataOutDtoWithComponents = [];
        resumeOutDto.resumeSectionDataOutDtos
            .sort((previousSectionDataOutDto, nextSectionDataOutDto) => previousSectionDataOutDto.rowIndex - nextSectionDataOutDto.rowIndex)
            .forEach(resumeSectionDataOutDto => {
                if (resumeSectionDataOutDto.columnIndex === 1) {
                    this.rightColumnResumeSectionDataOutDtoWithComponents.push(this.createResumeSectionDataOutDtoWithComponent(resumeSectionDataOutDto));
                } else {
                    this.leftColumnResumeSectionDataOutDtoWithComponents.push(this.createResumeSectionDataOutDtoWithComponent(resumeSectionDataOutDto));
                }
            });
    }

    private createResumeSectionDataOutDtoWithComponent(resumeSectionDataOutDto: ResumeSectionDataOutDto): ResumeSectionDataOutDtoWithComponent {
        return {
            resumeSectionDataOutDto: resumeSectionDataOutDto,
            component: this.getResumeSection(resumeSectionDataOutDto?.resumeSectionType)
        } as ResumeSectionDataOutDtoWithComponent;
    }

    private getResumeSection(resumeSectionType: RESUME_SECTION_TYPE): null {
        if (this.resumeOutDto?.atsLayout === ATS_LAYOUT_TYPE.MODERN) {
            return this.getResumeModernSection(resumeSectionType);
        } else {
            return this.getResumeClassicSection(resumeSectionType);
        }
    }

    private getResumeModernSection(sectionType: RESUME_SECTION_TYPE): any {
        switch (sectionType) {
            case RESUME_SECTION_TYPE.PERSONAL_INFORMATION:
                return RPersonalInformationComponent;
            case RESUME_SECTION_TYPE.SUMMARY:
                return RSummaryComponent;
            case RESUME_SECTION_TYPE.SOCIAL_LINKS:
                return null;
            case RESUME_SECTION_TYPE.EDUCATION:
                return REducationComponent;
            case RESUME_SECTION_TYPE.WORK_EXPERIENCE:
                return RWorkExperienceComponent;
            case RESUME_SECTION_TYPE.SKILL_GROUP:
                return RSkillComponent;
            case RESUME_SECTION_TYPE.PROJECTS:
                return RProjectComponent;
            case RESUME_SECTION_TYPE.CERTIFICATES:
                return RCertificateComponent;
            case RESUME_SECTION_TYPE.LANGUAGES:
                return RLanguageComponent;
            case RESUME_SECTION_TYPE.ACHIEVEMENTS:
                return RAchievementComponent;
            case RESUME_SECTION_TYPE.HOBBIES:
                return RHobbyComponent;
            case RESUME_SECTION_TYPE.REFERENCE:
                return RReferenceComponent;
            default:
                return null;
        }
    }

    private getResumeClassicSection(sectionType: RESUME_SECTION_TYPE): any {
        switch (sectionType) {
            case RESUME_SECTION_TYPE.PERSONAL_INFORMATION:
                return RPersonalInformationComponent;
            case RESUME_SECTION_TYPE.SUMMARY:
                return RSummaryComponent;
            case RESUME_SECTION_TYPE.SOCIAL_LINKS:
                return RSocialLinkComponent;
            case RESUME_SECTION_TYPE.EDUCATION:
                return REducationComponent;
            case RESUME_SECTION_TYPE.WORK_EXPERIENCE:
                return RWorkExperienceComponent;
            case RESUME_SECTION_TYPE.SKILL_GROUP:
                return RSkillComponent;
            case RESUME_SECTION_TYPE.PROJECTS:
                return RProjectComponent;
            case RESUME_SECTION_TYPE.CERTIFICATES:
                return RCertificateComponent;
            case RESUME_SECTION_TYPE.LANGUAGES:
                return RLanguageComponent;
            case RESUME_SECTION_TYPE.ACHIEVEMENTS:
                return RAchievementComponent;
            case RESUME_SECTION_TYPE.HOBBIES:
                return RHobbyComponent;
            case RESUME_SECTION_TYPE.REFERENCE:
                return RReferenceComponent;
            default:
                return null;
        }
    }

    private setResumeTheme(): void {
        this.resumeTheme = SelectThemeUtil.setResumeTheme(this.resumeOutDto?.atsLayout === ATS_LAYOUT_TYPE.CLASSIC, this.resumeOutDto?.layoutType, this.resumeOutDto?.theme);
    }

    private subscribeToResumeStore(): void {
        this.subscription.add(this.resumeStore.updatedResumeOutDto.subscribe(resumeOutDto => {
            if (resumeOutDto) {
                this.resumeOutDto = resumeOutDto;
                this.resumeOutDto?.layoutType === LAYOUT_TYPE.ONE_COLUMN ? this.handleOneColumn(resumeOutDto) : this.handleTwoColumn(resumeOutDto);
                this.setResumeTheme();
            }
        }));
    }
}

export class ResumeSectionDataOutDtoWithComponent {
    resumeSectionDataOutDto: ResumeSectionDataOutDto;
    component: any;
}
