<div class="mb-2 flex items-center justify-end">
    <div class="branding-container flex items-center justify-center">
        <div class="flex items-center justify-center branding-image-container">
            @if (!resumeOutDto?.removeBranding) {
                <img class="w-full h-full" [src]="resumeOutDto?.brandingImageUrl || defaultBrandingImageUrl"
                     alt="brandingImage"/>
            }
        </div>
    </div>
</div>
