@if (resumeHasSkillOutDtos?.length > 0) {
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.atsLayout == ATS_LAYOUT_TYPE.CLASSIC ? classicLayoutRef : modernLayoutTemplateRef">
    </ng-container>
}

<ng-template #classicLayoutRef>
    <div class="px-4">
        <div class="flex flex-row items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="resume-section-title"
                     [style.color]="resumeOutDto?.layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.primaryColor : resumeTheme?.twoColumnHighlightedHeadingText">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.SKILL | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor "
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        @for (resumeHasSkillOutDto of resumeHasSkillOutDtos; let index = $index; track resumeHasSkillOutDto.id) {
            <div class="resume-section-content-between">
                <div class="w-full flex flex-col justify-center items-stretch">
                    @if (resumeHasSkillOutDto?.title) {
                        <div class="resume-section-container-title"
                             [style.color]="resumeTheme.primaryColor">
                            {{ resumeHasSkillOutDto?.title }}
                        </div>
                    }
                    <div class="w-full flex flex-wrap justify-start flex-row items-center">
                        @for (skill of resumeHasSkillOutDto?.skills; track skill) {
                            <div class="resume-section-container-text mr-3 chip m-1 border-solid border"
                                 [style.border-color]="resumeTheme?.iconColor"
                                 [style.color]="resumeTheme?.skillChipTextColor"
                                 [style.background]="resumeTheme?.skillChipBackground">
                                {{ skill }}
                            </div>
                        }
                    </div>
                </div>
            </div>
            @if (index < resumeHasSkillOutDtos?.length - 1) {
                <hr class="w-full border-white-light resume-horizontal-line-below-distance"/>
            }
        }
    </div>
</ng-template>

<ng-template #modernLayoutTemplateRef>
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.layoutType === LAYOUT_TYPE.TWO_COLUMN ? twoColumnModernLayoutRef : oneColumnModernLayoutRef">
    </ng-container>
</ng-template>

<ng-template #oneColumnModernLayoutRef>
    <div class="px-4">
        <div class="flex flex-row items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="ti ti-award text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                </div>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.primaryColor">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.SKILL | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        @for (resumeHasSkillOutDto of resumeHasSkillOutDtos; let index = $index; track resumeHasSkillOutDto.id) {
            <div class="resume-section-content-between">
                <div class="w-full flex flex-col justify-center items-stretch">
                    @if (resumeHasSkillOutDto?.title) {
                        <div class="resume-section-container-title"
                             [style.color]="resumeTheme.primaryColor">
                            {{ resumeHasSkillOutDto?.title }}
                        </div>
                    }
                    <div class="w-full flex flex-wrap justify-start flex-row items-center">
                        @for (skill of resumeHasSkillOutDto?.skills; track skill) {
                            <div class="resume-section-container-text mr-3 chip m-1"
                                 [style.color]="resumeTheme?.skillChipTextColor"
                                 [style.background]="resumeTheme?.skillChipBackground">
                                {{ skill }}
                            </div>
                        }
                    </div>
                </div>
            </div>
            @if (index < resumeHasSkillOutDtos?.length - 1) {
                <hr class="w-full border-white-light resume-horizontal-line-below-distance"/>
            }
        }
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRef>
    <ng-container
        [ngTemplateOutlet]="resumeSectionDataOutDto?.columnIndex === 1 ? twoColumnModernLayoutRightRef :
        twoColumnModernLayoutLeftRef">
    </ng-container>
</ng-template>

<ng-template #twoColumnModernLayoutRightRef>
    <div class="px-4">
        <div class="flex flex-row items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="ti ti-award text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                </div>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.primaryColor">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.SKILL | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        @for (resumeHasSkillOutDto of resumeHasSkillOutDtos; let index = $index; track resumeHasSkillOutDto.id) {
            <div class="resume-section-content-between">
                <div class="w-full flex flex-col justify-center items-stretch">
                    @if (resumeHasSkillOutDto?.title) {
                        <div class="resume-section-container-title"
                             [style.color]="resumeTheme.primaryColor">
                            {{ resumeHasSkillOutDto?.title }}
                        </div>
                    }
                    <div class="w-full flex flex-wrap justify-start flex-row items-center">
                        @for (skill of resumeHasSkillOutDto?.skills; track skill) {
                            <div class="resume-section-container-text mr-3 chip m-1"
                                 [style.color]="resumeTheme?.skillChipTextColor"
                                 [style.background]="resumeTheme?.skillChipBackground">
                                {{ skill }}
                            </div>
                        }
                    </div>
                </div>
            </div>
            @if (index < resumeHasSkillOutDtos?.length - 1) {
                <hr class="w-full border-white-light resume-horizontal-line-below-distance"/>
            }
        }
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutLeftRef>
    <div class="px-4">
        <div class="flex flex-row items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="ti ti-award text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                </div>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeOutDto?.layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.primaryColor : resumeTheme?.twoColumnHighlightedHeadingText">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.SKILL | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor "
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        @for (resumeHasSkillOutDto of resumeHasSkillOutDtos; let index = $index; track resumeHasSkillOutDto.id) {
            <div class="resume-section-content-between">
                <div class="w-full flex flex-col justify-center items-stretch">
                    @if (resumeHasSkillOutDto?.title) {
                        <div class="resume-section-container-title"
                             [style.color]="resumeTheme.twoColumnHighlightedPrimaryText">
                            {{ resumeHasSkillOutDto?.title }}
                        </div>
                    }
                    <div class="w-full flex flex-wrap justify-start flex-row items-center">
                        @for (skill of resumeHasSkillOutDto?.skills; track skill) {
                            <div class="resume-section-container-text mr-3 chip m-1"
                                 [style.color]="resumeTheme?.highlightedChipText"
                                 [style.background]="resumeTheme?.highlightedChipBackground">
                                {{ skill }}
                            </div>
                        }
                    </div>
                </div>
            </div>
            @if (index < resumeHasSkillOutDtos?.length - 1) {
                <hr class="w-full border-white-light resume-horizontal-line-below-distance"/>
            }
        }
    </div>
</ng-template>
