import {Component, Input} from '@angular/core';
import {ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {ATS_LAYOUT_TYPE, LAYOUT_TYPE} from "../../common/layout-selection.model";
import {HOBBY_FREQUENCY_TYPE_DISPLAY, ResumeHasHobbyOutDto} from "../../common/resume-section-model/hobby.model";
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {SelectThemeUtil} from "../../common/select-theme.util";
import {ResumeStore} from "../../../ai-resume/common/store/resume.store";
import {NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {ResumeOutDto, ResumeSectionDataOutDto} from "../../common/resume.model";
import {Subscription} from "rxjs";

@Component({
    selector: 'tal-r-hobby',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        TitleCasePipe,
        NgClass,
        NgStyle
    ],
    templateUrl: './r-hobby.component.html',
    styleUrl: './r-hobby.component.scss'
})
export class RHobbyComponent {
    @Input() resumeSectionDataOutDto: ResumeSectionDataOutDto;
    resumeOutDto?: ResumeOutDto;
    resumeTheme?: ResumeThemeModel;
    resumeHasHobbyOutDtos: ResumeHasHobbyOutDto[];

    protected readonly LAYOUT_TYPE = LAYOUT_TYPE;
    protected readonly HOBBY_FREQUENCY_TYPE_DISPLAY = HOBBY_FREQUENCY_TYPE_DISPLAY;
    protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
    protected readonly ATS_LAYOUT_TYPE = ATS_LAYOUT_TYPE;

    private subscription = new Subscription();


    constructor(private resumeStore: ResumeStore) {
    }

    ngOnInit(): void {
        this.subscribeToResumeStore();
    }

    private subscribeToResumeStore(): void {
        this.subscription.add(this.resumeStore.updatedResumeOutDto
            .subscribe(resumeOutDto => {
                if (resumeOutDto) {
                    this.resumeOutDto = resumeOutDto;
                    this.resumeHasHobbyOutDtos = resumeOutDto?.hobbies;
                    this.setResumeTheme();
                }
            }));
    }

    private setResumeTheme(): void {
        this.resumeTheme = SelectThemeUtil.setResumeTheme(this.resumeOutDto.atsLayout === ATS_LAYOUT_TYPE.CLASSIC, this.resumeOutDto?.layoutType, this.resumeOutDto?.theme);
    }
}
