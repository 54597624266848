<ng-container
    [ngTemplateOutlet]="resumeOutDto?.atsLayout == ATS_LAYOUT_TYPE.CLASSIC ? classicLayoutRef : modernLayoutTemplateRef">
</ng-container>

<ng-template #classicLayoutRef>
    <div [style.border]="'2px solid var(--color-brown-extra-light)'"
         class="one-column-header-height grid p-4 min-h-36 grid-cols-1">
        @if (resumeHasPersonalInformationOutDto) {
            <div class="flex flex-col items-start justify-between h-full">
                <div class="flex flex-col items-start justify-between w-full">
                    <div class="flex flex-col items-start justify-star w-full">
                        <div class="flex items-center justify-between w-full title-container">
                            <div class="font-medium capitalize text-sm leading-4 sm:text-3xl sm:leading-9"
                                 [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                {{ resumeHasPersonalInformationOutDto?.fullName | titlecase }}
                            </div>
                        </div>
                        <div class="font-normal text-xs leading-4 sm:text-lg sm:leading-7"
                             [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                            {{ resumeHasPersonalInformationOutDto?.jobTitle }}
                        </div>
                    </div>
                </div>
                <div class="candidate-contact-info pt-2 flex flex-col items-start justify-between">
                    <div class="flex flex-col items-start justify-start">
                        <div class="flex flex-col sm:flex-row sm:gap-2">
                            @if (resumeHasPersonalInformationOutDto?.phone) {
                                <div class="flex flex-row items-center justify-start">
                                    <div class="font-normal text-xxs sm:text-sm"
                                         [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                        {{ resumeHasPersonalInformationOutDto?.phone }}
                                    </div>
                                </div>
                            }
                            @if (resumeHasPersonalInformationOutDto?.alternateContactNumber && resumeHasPersonalInformationOutDto?.phone) {
                                <div
                                    class="hidden sm:block"
                                    [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                    |
                                </div>
                            }
                            @if (resumeHasPersonalInformationOutDto?.alternateContactNumber) {
                                <div class="flex flex-row items-center justify-start">
                                    <div class="font-normal text-xxs sm:text-sm"
                                         [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                        <!--email_off-->{{ resumeHasPersonalInformationOutDto?.alternateContactNumber }}
                                        <!--/email_off-->
                                    </div>
                                </div>
                            }
                        </div>
                        @if (resumeHasPersonalInformationOutDto?.emailAddress) {
                            <div class="flex flex-row items-center justify-start">
                                <div class="text-xxs sm:text-sm font-normal"
                                     [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                    <!--email_off-->{{ resumeHasPersonalInformationOutDto?.emailAddress }}
                                    <!--/email_off-->
                                </div>
                            </div>
                        }
                    </div>
                    @if (resumeHasPersonalInformationOutDto?.city || resumeHasPersonalInformationOutDto?.country) {
                        <div [ngStyle]="{'color': resumeTheme.primaryColor}"
                             class="locations-details-blue flex flex-row items-center justify-start">
                            @if (resumeHasPersonalInformationOutDto?.city) {
                                <div class="text-xxs sm:text-sm font-normal"
                                     [class.resume-card-location-ellipsis]="resumeHasPersonalInformationOutDto?.city?.length > 18"
                                     [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                    {{ resumeHasPersonalInformationOutDto?.country ? (resumeHasPersonalInformationOutDto?.city | titlecase) + ',' : (resumeHasPersonalInformationOutDto?.city| titlecase) }}
                                </div>
                            }
                            @if (resumeHasPersonalInformationOutDto?.country) {
                                <div class="text-xxs sm:text-sm font-normal ml-1"
                                     [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                    {{ resumeHasPersonalInformationOutDto?.country | titlecase }}
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #modernLayoutTemplateRef>
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.layoutType === LAYOUT_TYPE.TWO_COLUMN ? twoColumnModernLayoutRef : oneColumnModernLayoutRef">
    </ng-container>
</ng-template>

<ng-template #oneColumnModernLayoutRef>
    <div [style.grid-template-columns]="resumeOutDto?.personalInformation?.imageUrl ? 'fit-content(100%) 1fr' :'1fr' "
         [style.border]="'2px solid var(--color-brown-extra-light)'"
         [style.background]="resumeTheme.highlightedBackground"
         class="one-column-header-height grid p-4 one-column-header-height min-h-36">
        @if (resumeOutDto?.personalInformation?.imageUrl) {
            <img [src]="resumeOutDto?.personalInformation?.imageUrl"
                 alt="profileImage"
                 [style.max-width]="'160px'"
                 [style.min-width]="'100px'"
                 [style.min-height]="'100px'"
                 [style.max-height]="'160px'"
                 class="w-full fade-in rounded-3xl"/>
        }
        @if (resumeHasPersonalInformationOutDto) {
            <div class="flex flex-col items-start justify-between h-full"
                 [ngClass]="(resumeOutDto?.personalInformation?.imageUrl) ? 'pl-4' : 'pl-0'">
                <div class="flex flex-col items-start justify-between w-full">
                    <div class="flex flex-col items-start justify-star w-full">
                        <div class="flex items-center justify-between w-full title-container">
                            <div class="font-medium capitalize text-sm leading-4 sm:text-3xl sm:leading-9"
                                 [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                {{ resumeHasPersonalInformationOutDto?.fullName | titlecase }}
                            </div>
                            @if (resumeOutDto?.socialLinks) {
                                <tal-r-social-link></tal-r-social-link>
                            }
                        </div>
                        <div class="font-normal text-xs leading-4 sm:text-lg sm:leading-7"
                             [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                            {{ resumeHasPersonalInformationOutDto?.jobTitle }}
                        </div>
                    </div>
                </div>
                <div class="candidate-contact-info pt-2 flex flex-col items-start justify-between">
                    <div class="flex flex-col items-start justify-start">
                        <div class="flex flex-col sm:flex-row sm:gap-2">
                            @if (resumeHasPersonalInformationOutDto?.phone) {
                                <div class="flex flex-row items-center justify-start">
                                    <div class="text-xs sm:text-base font-normal pr-1"
                                         [ngClass]="CONTACT_NUMBER_TYPE_ICON[resumeHasPersonalInformationOutDto?.contactNumberType] || 'ti ti-phone'"
                                         [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                    </div>
                                    <div class="font-normal text-xxs sm:text-sm"
                                         [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                        {{ resumeHasPersonalInformationOutDto?.phone }}
                                    </div>
                                </div>
                            }
                            @if (resumeHasPersonalInformationOutDto?.alternateContactNumber && resumeHasPersonalInformationOutDto?.phone) {
                                <div
                                    class="hidden sm:block"
                                    [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                    |
                                </div>
                            }
                            @if (resumeHasPersonalInformationOutDto?.alternateContactNumber) {
                                <div class="flex flex-row items-center justify-start">
                                    <div class="text-xs sm:text-base font-light pr-1"
                                         [ngClass]="CONTACT_NUMBER_TYPE_ICON[resumeHasPersonalInformationOutDto?.alternateContactNumberType] || 'ti ti-phone'"
                                         [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                    </div>
                                    <div class="font-normal text-xxs sm:text-sm"
                                         [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                        <!--email_off-->{{ resumeHasPersonalInformationOutDto?.alternateContactNumber }}
                                        <!--/email_off-->
                                    </div>
                                </div>
                            }
                        </div>
                        @if (resumeHasPersonalInformationOutDto?.emailAddress) {
                            <div class="flex flex-row items-center justify-start">
                                <div class="font-normal text-xs sm:text-base pr-1"
                                     [ngClass]="resumeIcon.email"
                                     [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                </div>
                                <div class="text-xxs sm:text-sm font-normal"
                                     [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                    <!--email_off-->{{ resumeHasPersonalInformationOutDto?.emailAddress }}
                                    <!--/email_off-->
                                </div>
                            </div>
                        }
                    </div>
                    @if (resumeHasPersonalInformationOutDto?.city || resumeHasPersonalInformationOutDto?.country) {
                        <div [ngStyle]="{'color': resumeTheme.oneColumnHighlightedPrimaryText}"
                             class="locations-details-blue flex flex-row items-center justify-start">
                            <div class="text-xs sm:text-base font-normal"
                                 [ngClass]="resumeIcon.location"
                                 [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                            </div>
                            @if (resumeHasPersonalInformationOutDto?.city) {
                                <div class="text-xxs sm:text-sm font-normal ml-1"
                                     [class.resume-card-location-ellipsis]="resumeHasPersonalInformationOutDto?.city?.length > 18"
                                     [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                    {{ resumeHasPersonalInformationOutDto?.country ? (resumeHasPersonalInformationOutDto?.city | titlecase) + ',' : (resumeHasPersonalInformationOutDto?.city| titlecase) }}
                                </div>
                            }
                            @if (resumeHasPersonalInformationOutDto?.country) {
                                <div class="text-xxs sm:text-sm font-normal ml-1"
                                     [style.color]="resumeTheme.oneColumnHighlightedPrimaryText">
                                    {{ resumeHasPersonalInformationOutDto?.country | titlecase }}
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRef>
    <div class="one-column-header-height grid one-column-header-height fit-content px-4 pt-4"
         [style.background]="resumeTheme?.pageBackground">
        @if (resumeHasPersonalInformationOutDto) {
            <div [ngClass]="'min-h-28 sm:min-h-40'">
                <div class="flex flex-col items-start justify-between h-full">
                    <div class="flex flex-col items-start justify-between w-full">
                        <div class="flex flex-col items-start justify-star w-full">
                            <div class="flex items-center justify-between w-full">
                                <div class="font-medium capitalize text-sm leading-4 sm:text-3xl sm:leading-9"
                                     [style.color]="resumeTheme.primaryColor">
                                    {{ resumeHasPersonalInformationOutDto?.fullName | titlecase }}
                                </div>
                                @if (resumeOutDto?.socialLinks) {
                                    <tal-r-social-link></tal-r-social-link>
                                }
                            </div>
                            <div class="font-normal text-xs leading-4 sm:text-lg sm:leading-7"
                                 [style.color]="resumeTheme.primaryColor">
                                {{ resumeHasPersonalInformationOutDto?.jobTitle }}
                            </div>
                        </div>
                    </div>
                    <div class="candidate-contact-info pt-2 flex flex-col items-start justify-between">
                        <div class="flex flex-col items-start justify-start">
                            <div class="flex flex-col sm:flex-row sm:gap-2">
                                @if (resumeHasPersonalInformationOutDto?.phone) {
                                    <div class="flex flex-row items-center justify-start">
                                        <div class="text-xs sm:text-base font-normal pr-1"
                                             [ngClass]="CONTACT_NUMBER_TYPE_ICON[resumeHasPersonalInformationOutDto?.contactNumberType] || 'ti ti-phone'"
                                             [style.color]="resumeTheme.primaryColor">
                                        </div>
                                        <div class="font-normal text-xxs sm:text-sm"
                                             [style.color]="resumeTheme.primaryColor">
                                            {{ resumeHasPersonalInformationOutDto?.phone }}
                                        </div>
                                    </div>
                                }
                                @if (resumeHasPersonalInformationOutDto?.alternateContactNumber && resumeHasPersonalInformationOutDto?.phone) {
                                    <div
                                        class="hidden sm:block"
                                        [style.color]="resumeTheme.primaryColor">
                                        |
                                    </div>
                                }
                                @if (resumeHasPersonalInformationOutDto?.alternateContactNumber) {
                                    <div class="flex flex-row items-center justify-start">
                                        <div class="text-xs sm:text-base font-light pr-1"
                                             [ngClass]="CONTACT_NUMBER_TYPE_ICON[resumeHasPersonalInformationOutDto?.alternateContactNumberType] || 'ti ti-phone'"
                                             [style.color]="resumeTheme.primaryColor">
                                        </div>
                                        <div class="font-normal text-xxs sm:text-sm"
                                             [style.color]="resumeTheme.primaryColor">
                                            <!--email_off-->{{ resumeHasPersonalInformationOutDto?.alternateContactNumber }}
                                            <!--/email_off-->
                                        </div>
                                    </div>
                                }
                            </div>
                            @if (resumeHasPersonalInformationOutDto?.emailAddress) {
                                <div class="flex flex-row items-center justify-start">
                                    <div class="font-normal text-xs sm:text-base pr-1"
                                         [ngClass]="resumeIcon.email"
                                         [style.color]="resumeTheme.primaryColor">
                                    </div>
                                    <div class="text-xxs sm:text-sm font-normal"
                                         [style.color]="resumeTheme.primaryColor">
                                        <!--email_off-->{{ resumeHasPersonalInformationOutDto?.emailAddress }}
                                        <!--/email_off-->
                                    </div>
                                </div>
                            }
                        </div>
                        @if (resumeHasPersonalInformationOutDto?.city || resumeHasPersonalInformationOutDto?.country) {
                            <div [ngStyle]="{'color': resumeTheme.primaryColor}"
                                 class="locations-details-blue flex flex-row items-center justify-start">
                                <div class="text-xs sm:text-base font-normal"
                                     [ngClass]="resumeIcon.location"
                                     [style.color]="resumeTheme.primaryColor">
                                </div>
                                @if (resumeHasPersonalInformationOutDto?.city) {
                                    <div class="text-xxs sm:text-sm font-normal ml-1"
                                         [class.resume-card-location-ellipsis]="resumeHasPersonalInformationOutDto?.city?.length > 18"
                                         [style.color]="resumeTheme.primaryColor">
                                        {{ resumeHasPersonalInformationOutDto?.country ? (resumeHasPersonalInformationOutDto?.city | titlecase) + ',' : (resumeHasPersonalInformationOutDto?.city| titlecase) }}
                                    </div>
                                }
                                @if (resumeHasPersonalInformationOutDto?.country) {
                                    <div class="text-xxs sm:text-sm font-normal ml-1"
                                         [style.color]="resumeTheme.primaryColor">
                                        {{ resumeHasPersonalInformationOutDto?.country | titlecase }}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>
