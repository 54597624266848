@if (resumeHasProjectOutDtos?.length > 0) {
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.atsLayout == ATS_LAYOUT_TYPE.CLASSIC ? classicLayoutRef : modernLayoutTemplateRef">
    </ng-container>
}

<ng-template #classicLayoutRef>
    <div class="px-4">
        <div class="flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="flex flex-row items-center resume-section-title"
                     [ngStyle]="{'color': resumeTheme.primaryColor}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.PROJECTS | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="section-description">
            <div class="w-full">
                @for (resumeHasProjectOutDto of resumeHasProjectOutDtos; let index = $index; track resumeHasProjectOutDto?.id) {
                    <div
                        class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                        <div class="w-full">
                            <div class="flex items-start justify-between">
                                <div class="break-all flex flex-col items-start justify-between"
                                     [ngClass]="resumeHasProjectOutDto?.teamSize || resumeHasProjectOutDto?.startDate ? 'w-3/5' : 'w-full'">
                                    <div class="flex flex-row items-center justify-start">
                                        <div class="resume-section-container-title break-normal"
                                             [ngStyle]="{'color': resumeTheme.primaryColor}">
                                            {{ resumeHasProjectOutDto?.projectTitle }}
                                        </div>
                                    </div>
                                    <div [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                         class="resume-section-container-text break-normal">
                                        {{ resumeHasProjectOutDto?.yourRole }}
                                        @if (resumeHasProjectOutDto?.yourRole && resumeHasProjectOutDto?.workedFor) {
                                            &#64;
                                        }
                                        @if (resumeHasProjectOutDto?.workedFor) {
                                            <span>{{ resumeHasProjectOutDto?.workedFor }}</span>
                                        }
                                    </div>
                                </div>
                                <div class="resume-section-container-text flex flex-col items-center justify-between">
                                    @if (resumeHasProjectOutDto?.startDate) {
                                        <div class="resume-section-container-text break-normal"
                                             [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                            {{ resumeHasProjectOutDto?.startDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                            -
                                            @if (resumeHasProjectOutDto?.workingHere || !resumeHasProjectOutDto?.endDate) {
                                                <span>Present</span>
                                            } @else {
                                                {{ resumeHasProjectOutDto?.endDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                            }
                                        </div>
                                    }
                                    @if (resumeHasProjectOutDto?.teamSize) {
                                        <div class="chip px-2 text-xxs sm:text-xs"
                                             [ngStyle]="{'background': resumeTheme?.skillChipBackground, 'color': resumeTheme?.secondaryColor}">
                                            Team size
                                            - {{ resumeHasProjectOutDto?.teamSize }}
                                        </div>
                                    }
                                </div>
                            </div>
                            @if (resumeHasProjectOutDto?.description) {
                                <div class="resume-section-container-description"
                                     [ngStyle]="{'color': resumeTheme?.primaryColor}"
                                     [innerHTML]="resumeHasProjectOutDto?.description"></div>
                            }
                            <div class="flex flex-wrap">
                                @for (tool of resumeHasProjectOutDto?.toolsAndTechnologies; track tool?.id) {
                                    <div
                                        class="chip p-1 m-1 text-blue-1000 text-xxs sm:text-xs break-normal border-solid border"
                                        [style.border-color]="resumeTheme?.iconColor"
                                        [ngStyle]="{'background': resumeTheme?.skillChipBackground,
                                        'color': resumeTheme?.skillChipTextColor}">
                                        {{ tool.name }}
                                    </div>
                                }
                            </div>
                            @if (resumeHasProjectOutDto?.projectLink) {
                                <a [href]="resumeHasProjectOutDto?.projectLink"
                                   class="color-primary resume-section-container-text">Link</a>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modernLayoutTemplateRef>
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.layoutType === LAYOUT_TYPE.TWO_COLUMN ? twoColumnModernLayoutRef : oneColumnModernLayoutRef">
    </ng-container>
</ng-template>

<ng-template #oneColumnModernLayoutRef>
    <div class="px-4">
        <div class="flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="text-sm sm:text-2xl"
                     [ngClass]="ResumeIconModel.getIconForResume().project"
                     [ngStyle]="{'color': resumeTheme.iconColor}">
                </div>
                <div class="flex flex-row items-center resume-section-title pl-1"
                     [ngStyle]="{'color': resumeTheme.primaryColor}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.PROJECTS | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="section-description">
            <div class="w-full">
                @for (resumeHasProjectOutDto of resumeHasProjectOutDtos; let index = $index; track resumeHasProjectOutDto?.id) {
                    <div
                        class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                        <div class="w-full">
                            <div class="flex items-start justify-between">
                                <div class="break-all flex flex-col items-start justify-between"
                                     [ngClass]="resumeHasProjectOutDto?.teamSize || resumeHasProjectOutDto?.startDate ? 'w-3/5' : 'w-full'">
                                    <div class="flex flex-row items-center justify-start">
                                        <div class="resume-section-container-title break-normal"
                                             [ngStyle]="{'color': resumeTheme.primaryColor}">
                                            {{ resumeHasProjectOutDto?.projectTitle }}
                                        </div>
                                        @if (resumeHasProjectOutDto?.projectLink) {
                                            <div [ngClass]="ResumeIconModel.getIconForResume().externalLink"
                                                 class="text-sm sm:text-xl leading-none pl-2 cursor-pointer"
                                                 [ngStyle]="{'color': resumeTheme.iconColor}"
                                                 (click)="openUrlInNewTab(resumeHasProjectOutDto?.projectLink)">
                                            </div>
                                        }
                                    </div>
                                    <div [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                         class="resume-section-container-text break-normal">
                                        {{ resumeHasProjectOutDto?.yourRole }}
                                        @if (resumeHasProjectOutDto?.yourRole && resumeHasProjectOutDto?.workedFor) {
                                            &#64;
                                        }
                                        @if (resumeHasProjectOutDto?.workedFor) {
                                            <span>{{ resumeHasProjectOutDto?.workedFor }}</span>
                                        }
                                    </div>
                                </div>
                                <div class="resume-section-container-text flex flex-col items-center justify-between">
                                    @if (resumeHasProjectOutDto?.startDate) {
                                        <div class="resume-section-container-text break-normal"
                                             [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                            {{ resumeHasProjectOutDto?.startDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                            -
                                            @if (resumeHasProjectOutDto?.workingHere || !resumeHasProjectOutDto?.endDate) {
                                                <span>Present</span>
                                            } @else {
                                                {{ resumeHasProjectOutDto?.endDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                            }
                                        </div>
                                    }
                                    @if (resumeHasProjectOutDto?.teamSize) {
                                        <div class="chip px-2 text-xxs sm:text-xs"
                                             [ngStyle]="{'background': resumeTheme?.skillChipBackground, 'color': resumeTheme?.skillChipTextColor}">
                                            Team size
                                            - {{ resumeHasProjectOutDto?.teamSize }}
                                        </div>
                                    }
                                </div>
                            </div>
                            @if (resumeHasProjectOutDto?.description) {
                                <div class="resume-section-container-description"
                                     [ngStyle]="{'color': resumeTheme?.primaryColor}"
                                     [innerHTML]="resumeHasProjectOutDto?.description"></div>
                            }
                            <div class="flex flex-wrap">
                                @for (tool of resumeHasProjectOutDto?.toolsAndTechnologies; track tool?.id) {
                                    <div class="chip p-1 m-1 text-blue-1000 text-xxs sm:text-xs break-normal"
                                         [ngStyle]="{'background': resumeTheme?.skillChipBackground,
                                        'color': resumeTheme?.skillChipTextColor}">
                                        {{ tool.name }}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRef>
    <ng-container
        [ngTemplateOutlet]="resumeSectionDataOutDto?.columnIndex === 1 ? twoColumnModernLayoutRightRef :
        twoColumnModernLayoutLeftRef">
    </ng-container>
</ng-template>

<ng-template #twoColumnModernLayoutLeftRef>
    <div class="px-4">
        <div class="flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="text-sm sm:text-2xl"
                     [ngClass]="ResumeIconModel.getIconForResume().project"
                     [ngStyle]="{'color': resumeTheme.iconColor}">
                </div>
                <div class="flex flex-row items-center resume-section-title pl-1"
                     [ngStyle]="{'color': resumeTheme.twoColumnHighlightedHeadingText}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.PROJECTS | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="section-description">
            <div class="w-full">
                @for (resumeHasProjectOutDto of resumeHasProjectOutDtos; let index = $index; track resumeHasProjectOutDto?.id) {
                    <div
                        class="w-full flex flex-col items-center justify-center resume-section-content-between">
                        <div class="w-full">
                            <div class="flex flex-col items-start justify-between">
                                <div class="break-all flex flex-col items-start justify-between w-full">
                                    <div class="flex flex-row items-center justify-start">
                                        <div class="resume-section-container-title break-normal"
                                             [ngStyle]="{'color': resumeTheme.twoColumnHighlightedHeadingText}">
                                            {{ resumeHasProjectOutDto?.projectTitle }}
                                        </div>
                                        @if (resumeHasProjectOutDto?.projectLink) {
                                            <div [ngClass]="ResumeIconModel.getIconForResume().externalLink"
                                                 class="text-sm sm:text-xl leading-none pl-2 cursor-pointer"
                                                 [ngStyle]="{'color': resumeTheme.iconColor}"
                                                 (click)="openUrlInNewTab(resumeHasProjectOutDto?.projectLink)">
                                            </div>
                                        }
                                    </div>
                                    <div [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                         class="resume-section-container-text break-normal">
                                        {{ resumeHasProjectOutDto?.yourRole }}
                                        @if (resumeHasProjectOutDto?.yourRole && resumeHasProjectOutDto?.workedFor) {
                                            &#64;
                                        }
                                        @if (resumeHasProjectOutDto?.workedFor) {
                                            <span>{{ resumeHasProjectOutDto?.workedFor }}</span>
                                        }
                                    </div>
                                </div>
                                <div
                                    class="resume-section-container-text flex flex-col items-center justify-between">
                                    @if (resumeHasProjectOutDto?.startDate) {
                                        <div class="resume-section-container-text break-normal"
                                             [ngStyle]="{'color': resumeTheme.twoColumnHighlightedHeadingText}">
                                            {{ resumeHasProjectOutDto?.startDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                            -
                                            @if (resumeHasProjectOutDto?.workingHere || !resumeHasProjectOutDto?.endDate) {
                                                <span>Present</span>
                                            } @else {
                                                {{ resumeHasProjectOutDto?.endDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                            }
                                        </div>
                                    }
                                    @if (resumeHasProjectOutDto?.teamSize) {
                                        <div class="chip px-2 text-xxs sm:text-xs"
                                             [ngStyle]="{'background': resumeTheme?.skillChipBackground, 'color': resumeTheme?.skillChipTextColor}">
                                            Team size
                                            - {{ resumeHasProjectOutDto?.teamSize }}
                                        </div>
                                    }
                                </div>
                            </div>
                            @if (resumeHasProjectOutDto?.description) {
                                <div class="resume-section-container-description"
                                     [ngStyle]="{'color': resumeTheme?.twoColumnHighlightedHeadingText}"
                                     [innerHTML]="resumeHasProjectOutDto?.description"></div>
                            }
                            <div class="flex flex-wrap">
                                @for (tool of resumeHasProjectOutDto?.toolsAndTechnologies; track tool?.id) {
                                    <div class="chip p-1 m-1 text-blue-1000 text-xxs sm:text-xs break-normal"
                                         [ngStyle]="{'background': resumeTheme?.skillChipBackground,
                                        'color': resumeTheme?.skillChipTextColor}">
                                        {{ tool.name }}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRightRef>
    <div class="px-4">
        <div class="flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="text-sm sm:text-2xl"
                     [ngClass]="ResumeIconModel.getIconForResume().project"
                     [ngStyle]="{'color': resumeTheme.iconColor}">
                </div>
                <div class="flex flex-row items-center resume-section-title pl-1"
                     [ngStyle]="{'color': resumeTheme.primaryColor}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.PROJECTS | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="section-description">
            <div class="w-full">
                @for (resumeHasProjectOutDto of resumeHasProjectOutDtos; let index = $index; track resumeHasProjectOutDto?.id) {
                    <div
                        class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                        <div class="w-full">
                            <div class="flex items-start justify-between">
                                <div class="break-all flex flex-col items-start justify-between"
                                     [ngClass]="resumeHasProjectOutDto?.teamSize || resumeHasProjectOutDto?.startDate ? 'w-3/5' : 'w-full'">
                                    <div class="flex flex-row items-center justify-start">
                                        <div class="resume-section-container-title break-normal"
                                             [ngStyle]="{'color': resumeTheme.primaryColor}">
                                            {{ resumeHasProjectOutDto?.projectTitle }}
                                        </div>
                                        @if (resumeHasProjectOutDto?.projectLink) {
                                            <div [ngClass]="ResumeIconModel.getIconForResume().externalLink"
                                                 class="text-sm sm:text-xl leading-none pl-2 cursor-pointer"
                                                 [ngStyle]="{'color': resumeTheme.iconColor}"
                                                 (click)="openUrlInNewTab(resumeHasProjectOutDto?.projectLink)">
                                            </div>
                                        }
                                    </div>
                                    <div [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                         class="resume-section-container-text break-normal">
                                        {{ resumeHasProjectOutDto?.yourRole }}
                                        @if (resumeHasProjectOutDto?.yourRole && resumeHasProjectOutDto?.workedFor) {
                                            &#64;
                                        }
                                        @if (resumeHasProjectOutDto?.workedFor) {
                                            <span>{{ resumeHasProjectOutDto?.workedFor }}</span>
                                        }
                                    </div>
                                </div>
                                <div class="resume-section-container-text flex flex-col items-center justify-between">
                                    @if (resumeHasProjectOutDto?.startDate) {
                                        <div class="resume-section-container-text break-normal"
                                             [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                            {{ resumeHasProjectOutDto?.startDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                            -
                                            @if (resumeHasProjectOutDto?.workingHere || !resumeHasProjectOutDto?.endDate) {
                                                <span>Present</span>
                                            } @else {
                                                {{ resumeHasProjectOutDto?.endDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                            }
                                        </div>
                                    }
                                    @if (resumeHasProjectOutDto?.teamSize) {
                                        <div class="chip px-2 text-xxs sm:text-xs"
                                             [ngStyle]="{'background': resumeTheme?.skillChipBackground, 'color': resumeTheme?.skillChipTextColor}">
                                            Team size
                                            - {{ resumeHasProjectOutDto?.teamSize }}
                                        </div>
                                    }
                                </div>
                            </div>
                            @if (resumeHasProjectOutDto?.description) {
                                <div class="resume-section-container-description"
                                     [ngStyle]="{'color': resumeTheme?.primaryColor}"
                                     [innerHTML]="resumeHasProjectOutDto?.description"></div>
                            }
                            <div class="flex flex-wrap">
                                @for (tool of resumeHasProjectOutDto?.toolsAndTechnologies; track tool?.id) {
                                    <div class="chip p-1 m-1 text-blue-1000 text-xxs sm:text-xs break-normal"
                                         [ngStyle]="{'background': resumeTheme?.skillChipBackground,
                                        'color': resumeTheme?.skillChipTextColor}">
                                        {{ tool.name }}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>
