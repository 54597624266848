import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ResumeOutDto} from '../candidate/resume/common/resume.model';
import {JobPostOutDto} from "../employer/service/public-api-job-post.service";
import {OrganizationOutDto} from "../employer/service/public-api-organization.service";

@Injectable({
    providedIn: 'root'
})
export class MetaTagService {

    constructor(private titleService: Title,
                private metaService: Meta) {
    }

    removeTags(): void {
        this.metaService.removeTag('name="description"');
        this.metaService.removeTag('property="og:url"');
        this.metaService.removeTag('property="og:type"');
        this.metaService.removeTag('property="og:title"');
        this.metaService.removeTag('property="og:description"');
        this.metaService.removeTag('property="og:image"');
        this.metaService.removeTag('name="twitter:card"');
        this.metaService.removeTag('property="twitter:domain"');
        this.metaService.removeTag('property="twitter:url"');
        this.metaService.removeTag('name="twitter:title"');
        this.metaService.removeTag('name="twitter:description"');
        this.metaService.removeTag('name="twitter:image"');
    }

    addResumeTags(resumeOutDto: ResumeOutDto): void {
        this.metaService.updateTag({name: 'title', content: this.getCandidateTitle(resumeOutDto)});
        this.metaService.updateTag({name: 'description', content: this.getResumeDescription(resumeOutDto)});
        this.metaService.updateTag({property: 'og:url', content: this.getSocialLink(resumeOutDto)});
        this.metaService.updateTag({property: 'og:type', content: 'website'});
        this.metaService.updateTag({property: 'og:title', content: this.getCandidateTitle(resumeOutDto)});
        this.metaService.updateTag({property: 'og:description', content: this.getResumeDescription(resumeOutDto)});
        this.metaService.updateTag({
            property: 'og:image',
            content: resumeOutDto?.personalInformation?.imageUrl
        });
        this.metaService.updateTag({name: 'twitter:card', content: resumeOutDto?.personalInformation?.imageUrl});
        this.metaService.updateTag({property: 'twitter:domain', content: 'showcase.talenlio.com'});
        this.metaService.updateTag({property: 'twitter:url', content: this.getSocialLink(resumeOutDto)});
        this.metaService.updateTag({name: 'twitter:title', content: this.getCandidateTitle(resumeOutDto)});
        this.metaService.updateTag({
            name: 'twitter:description',
            content: resumeOutDto?.personalInformation?.jobTitle || 'Awesome resume'
        });
        this.metaService.updateTag({
            name: 'twitter:image',
            content: resumeOutDto?.personalInformation?.imageUrl
        });
    }

    addCareerSiteTags(organizationOutDto: OrganizationOutDto) {
        this.setTitle(this.getCompanyTitle(organizationOutDto));
        this.metaService.updateTag({name: 'title', content: this.getCompanyTitle(organizationOutDto)});
        this.metaService.updateTag({name: 'description', content: this.getCompanyDescription(organizationOutDto)});
        this.metaService.updateTag({property: 'og:url', content: this.getCompanySocialLink(organizationOutDto)});
        this.metaService.updateTag({property: 'og:type', content: 'website'});
        this.metaService.updateTag({property: 'og:title', content: this.getCompanyTitle(organizationOutDto)});
        this.metaService.updateTag({property: 'og:description', content: this.getCompanyDescription(organizationOutDto)});
        this.metaService.updateTag({
            property: 'og:image',
            content: organizationOutDto?.careerSite?.coverPictureFileStore?.url
        });
        this.metaService.updateTag({name: 'twitter:title', content: this.getCompanyTitle(organizationOutDto)});
        this.metaService.updateTag({name: 'twitter:description', content: this.getCompanyDescription(organizationOutDto)});
        this.metaService.updateTag({
            name: 'twitter:image',
            content: organizationOutDto?.careerSite?.coverPictureFileStore?.url
        });
    }

    addJobPostTags(jobPostOutDto: JobPostOutDto) {
        let organizationMinOutDto = jobPostOutDto?.organizationMinOutDto;
        this.setTitle(organizationMinOutDto?.name);
        this.metaService.updateTag({name: 'title', content: jobPostOutDto?.jobTitle});
        this.metaService.updateTag({name: 'description', content: this.getJobDescription(jobPostOutDto)});
        this.metaService.updateTag({property: 'og:url', content: organizationMinOutDto?.urlHandle});
        this.metaService.updateTag({property: 'og:type', content: 'website'});
        this.metaService.updateTag({property: 'og:title', content: jobPostOutDto?.jobTitle});
        this.metaService.updateTag({property: 'og:description', content: this.getJobDescription(jobPostOutDto)});
        this.metaService.updateTag({
            property: 'og:image',
            content: organizationMinOutDto?.logoUrl
        });
        this.metaService.updateTag({name: 'twitter:title', content: jobPostOutDto?.jobTitle});
        this.metaService.updateTag({name: 'twitter:description', content: this.getJobDescription(jobPostOutDto)});
        this.metaService.updateTag({
            name: 'twitter:image',
            content: organizationMinOutDto?.logoUrl
        });
    }

    private getJobDescription(jobPostOutDto: JobPostOutDto) {
        return (jobPostOutDto?.description || '').replace(/<\/?[^>]+(>|$)/g, '');
    }

    setTitle(title: string) {
        this.titleService.setTitle(title);
    }

    setMeta(name: string, content: string) {
        this.metaService.addTag({name, content});
    }

    setDescription(description: string) {
        this.setMeta('description', description);
    }

    setKeywords(keywords: string[]) {
        this.setMeta('keywords', keywords.join(', '));
    }

    private getCandidateTitle(resumeOutDto: ResumeOutDto) {
        return resumeOutDto?.personalInformation?.fullName || 'Awesome resume';
    }

    private getResumeDescription(resumeOutDto: ResumeOutDto) {
        return resumeOutDto?.personalInformation?.jobTitle || 'Awesome resume';
    }

    private getSocialLink(resumeOutDto: ResumeOutDto) {
        let socialLink = 'https://showcase.talenlio.com';
        if (resumeOutDto && resumeOutDto.socialLinks && resumeOutDto.socialLinks.length > 0) {
            socialLink = resumeOutDto.socialLinks[0].name;
        }
        return socialLink;
    }

    private getCompanyTitle(organizationOutDto: OrganizationOutDto) {
        return organizationOutDto?.name || '';
    }

    private getCompanyDescription(organizationOutDto: OrganizationOutDto) {
        return (organizationOutDto?.careerSite?.summary || '').replace(/<\/?[^>]+(>|$)/g, '');
    }

    private getCompanySocialLink(organizationOutDto: OrganizationOutDto) {
        return organizationOutDto?.careerSite?.websiteLink || '';
    }
}
