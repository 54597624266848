@if (resumeHasEducationOutDtos?.length > 0) {
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.atsLayout == ATS_LAYOUT_TYPE.CLASSIC ? classicLayoutRef : modernLayoutTemplateRef">
    </ng-container>
}

<ng-template #classicLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex flex-row items-center justify-start resume-section-title-bottom">
            <div class="flex flex-row items-center justify-start">
                <div class="resume-section-title"
                     [style.color]="resumeOutDto?.layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.primaryColor : resumeTheme.twoColumnHighlightedHeadingText">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.EDUCATION | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="section-description">
            <div class="w-full">
                @for (resumeHasEducationOutDto of resumeHasEducationOutDtos; let index = $index; track resumeHasEducationOutDto?.id) {
                    <div
                        class="mt-2 mb-4 w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                        <div class="w-full">
                            <div class="flex flex-col sm:flex-row items-start justify-between">
                                <div class="sm:w-3/5 break-all flex flex-col items-start justify-between">
                                    <div class="flex items-start justify-start">
                                        @if (resumeHasEducationOutDto?.degree) {
                                            <div class="resume-section-container-text"
                                                 [ngStyle]="{'color': resumeTheme.primaryColor}">
                      <span
                          class="resume-section-container-title break-normal"> {{ resumeHasEducationOutDto?.degree }}</span>
                                                @if (resumeHasEducationOutDto?.specialization) {
                                                    <span class="resume-section-container-text break-normal"
                                                          [ngStyle]="{'color': resumeTheme.primaryColor}">
                      <span class="mx-0.5">|</span>
                                                        {{ resumeHasEducationOutDto?.specialization }}
                    </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                    @if (resumeHasEducationOutDto?.university) {
                                        <div [ngStyle]="{'color': resumeTheme.secondaryColor}"
                                             class="resume-section-container-text break-normal">
                                            {{ resumeHasEducationOutDto?.university }}
                                        </div>
                                    }
                                </div>
                                <div
                                    class="w-full text-xxs sm:text-sm sm:w-2/5 break-all sm:ml-4 flex flex-row sm:flex-col sm:items-end justify-between items-center">
                                    <div class="flex items-end justify-between">
                                        @if (resumeHasEducationOutDto.startDate) {
                                            <div class="resume-section-container-text break-normal"
                                                 [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                                {{ resumeHasEducationOutDto.startDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                                -
                                                <ng-container
                                                    [ngTemplateOutlet]="(resumeHasEducationOutDto.ongoing) || !resumeHasEducationOutDto?.endDate? presentBlock : endDateBlock">
                                                </ng-container>
                                                <ng-template #presentBlock>
                                                    <span [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                                          class="resume-section-container-text">Present
                                                    </span>
                                                </ng-template>
                                                <ng-template #endDateBlock>
                                    <span class="resume-section-container-text"
                                          [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                        {{ resumeHasEducationOutDto?.endDate ? (resumeHasEducationOutDto?.endDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR) : '--' }}
                                    </span>
                                                </ng-template>
                                            </div>
                                        }
                                        @if (resumeHasEducationOutDto?.grade) {
                                            <span
                                                class="ml-2 chip font-normal text-xxs sm:text-xs px-2 mt-1 break-normal"
                                                [ngStyle]="{'background': resumeTheme?.skillChipBackground, 'color': resumeTheme?.skillChipTextColor}">
                                                 Grade {{ resumeHasEducationOutDto?.grade }}
                                             </span>
                                        }
                                    </div>
                                    @if (resumeHasEducationOutDto?.region) {
                                        <div class="px-2 resume-section-container-text break-normal"
                                             [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                            {{ resumeHasEducationOutDto?.region | titlecase }}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modernLayoutTemplateRef>
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.layoutType === LAYOUT_TYPE.TWO_COLUMN ? twoColumnModernLayoutRef : oneColumnModernLayoutRef">
    </ng-container>
</ng-template>

<ng-template #oneColumnModernLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex flex-row items-center justify-start resume-section-title-bottom">
            <div class="flex flex-row items-center justify-start">
                <i class="w-4 ti ti-school text:sm sm:text-2xl sm:w-6"
                   [style.color]="resumeTheme.iconColor"></i>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.primaryColor">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.EDUCATION | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="section-description">
            <div class="w-full">
                @for (resumeHasEducationOutDto of resumeHasEducationOutDtos; let index = $index; track resumeHasEducationOutDto?.id) {
                    <div
                        class="mt-2 mb-4 w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                        <div class="w-full">
                            <div class="flex flex-col sm:flex-row items-start justify-between">
                                <div class="sm:w-3/5 break-all flex flex-col items-start justify-between">
                                    <div class="flex items-start justify-start">
                                        @if (resumeHasEducationOutDto?.degree) {
                                            <div class="resume-section-container-text"
                                                 [ngStyle]="{'color': resumeTheme.primaryColor}">
                      <span
                          class="resume-section-container-title break-normal"> {{ resumeHasEducationOutDto?.degree }}</span>
                                                @if (resumeHasEducationOutDto?.specialization) {
                                                    <span class="resume-section-container-text break-normal"
                                                          [ngStyle]="{'color': resumeTheme.primaryColor}">
                      <span class="mx-0.5">|</span>
                                                        {{ resumeHasEducationOutDto?.specialization }}
                    </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                    @if (resumeHasEducationOutDto?.university) {
                                        <div [ngStyle]="{'color': resumeTheme.secondaryColor}"
                                             class="resume-section-container-text break-normal">
                                            {{ resumeHasEducationOutDto?.university }}
                                        </div>
                                    }
                                </div>
                                <div
                                    class="w-full text-xxs sm:text-sm sm:w-2/5 break-all sm:ml-4 flex flex-row sm:flex-col sm:items-end justify-between items-center">
                                    <div class="flex items-end justify-between">
                                        @if (resumeHasEducationOutDto.startDate) {
                                            <div class="resume-section-container-text break-normal"
                                                 [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                                {{ resumeHasEducationOutDto.startDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                                -
                                                <ng-container
                                                    [ngTemplateOutlet]="(resumeHasEducationOutDto.ongoing) || !resumeHasEducationOutDto?.endDate? presentBlock : endDateBlock">
                                                </ng-container>
                                                <ng-template #presentBlock>
                    <span [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                          class="resume-section-container-text">Present
                    </span>
                                                </ng-template>
                                                <ng-template #endDateBlock>
                                    <span class="resume-section-container-text"
                                          [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                        {{ resumeHasEducationOutDto?.endDate ? (resumeHasEducationOutDto?.endDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR) : '--' }}
                                    </span>
                                                </ng-template>
                                            </div>
                                        }
                                        @if (resumeHasEducationOutDto?.grade) {
                                            <span
                                                class="ml-2 chip font-normal text-xxs sm:text-xs px-2 mt-1 break-normal"
                                                [ngStyle]="{'background': resumeTheme?.skillChipBackground, 'color': resumeTheme?.skillChipTextColor}">
                                         Grade {{ resumeHasEducationOutDto?.grade }}
                    </span>
                                        }
                                    </div>
                                    @if (resumeHasEducationOutDto?.region) {
                                        <div class="px-2 resume-section-container-text break-normal"
                                             [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                            {{ resumeHasEducationOutDto?.region | titlecase }}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRef>
    <ng-container
        [ngTemplateOutlet]="resumeSectionDataOutDto?.columnIndex === 1 ? twoColumnModernLayoutRightRef :
        twoColumnModernLayoutLeftRef">
    </ng-container>
</ng-template>

<ng-template #twoColumnModernLayoutRightRef>
    <div class="px-4">
        <div class="section-title-right flex flex-row items-center justify-start resume-section-title-bottom">
            <div class="flex flex-row items-center justify-start">
                <i class="w-4 ti ti-school text:sm sm:text-2xl sm:w-6"
                   [style.color]="resumeTheme.iconColor"></i>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.primaryColor">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.EDUCATION | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="section-description">
            <div class="w-full">
                @for (resumeHasEducationOutDto of resumeHasEducationOutDtos; let index = $index; track resumeHasEducationOutDto?.id) {
                    <div
                        class="mt-2 mb-4 w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                        <div class="w-full">
                            <div class="flex flex-col sm:flex-row items-start justify-between">
                                <div class="sm:w-3/5 break-all flex flex-col items-start justify-between">
                                    <div class="flex items-start justify-start">
                                        @if (resumeHasEducationOutDto?.degree) {
                                            <div class="resume-section-container-text"
                                                 [ngStyle]="{'color': resumeTheme.primaryColor}">
                      <span
                          class="resume-section-container-title break-normal"> {{ resumeHasEducationOutDto?.degree }}</span>
                                                @if (resumeHasEducationOutDto?.specialization) {
                                                    <span class="resume-section-container-text break-normal"
                                                          [ngStyle]="{'color': resumeTheme.primaryColor}">
                      <span class="mx-0.5">|</span>
                                                        {{ resumeHasEducationOutDto?.specialization }}
                    </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                    @if (resumeHasEducationOutDto?.university) {
                                        <div [ngStyle]="{'color': resumeTheme.secondaryColor}"
                                             class="resume-section-container-text break-normal">
                                            {{ resumeHasEducationOutDto?.university }}
                                        </div>
                                    }
                                </div>
                                <div
                                    class="w-full text-xxs sm:text-sm sm:w-2/5 break-all sm:ml-4 flex flex-row sm:flex-col sm:items-end justify-between items-center">
                                    <div class="flex items-end justify-between">
                                        @if (resumeHasEducationOutDto.startDate) {
                                            <div class="resume-section-container-text break-normal"
                                                 [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                                {{ resumeHasEducationOutDto.startDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                                -
                                                <ng-container
                                                    [ngTemplateOutlet]="(resumeHasEducationOutDto.ongoing) || !resumeHasEducationOutDto?.endDate? presentBlock : endDateBlock">
                                                </ng-container>
                                                <ng-template #presentBlock>
                    <span [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                          class="resume-section-container-text">Present
                    </span>
                                                </ng-template>
                                                <ng-template #endDateBlock>
                                    <span class="resume-section-container-text"
                                          [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                        {{ resumeHasEducationOutDto?.endDate ? (resumeHasEducationOutDto?.endDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR) : '--' }}
                                    </span>
                                                </ng-template>
                                            </div>
                                        }
                                        @if (resumeHasEducationOutDto?.grade) {
                                            <span
                                                class="ml-2 chip font-normal text-xxs sm:text-xs px-2 mt-1 break-normal"
                                                [ngStyle]="{'background': resumeTheme?.skillChipBackground, 'color': resumeTheme?.skillChipTextColor}">
                                         Grade {{ resumeHasEducationOutDto?.grade }}
                    </span>
                                        }
                                    </div>
                                    @if (resumeHasEducationOutDto?.region) {
                                        <div class="px-2 resume-section-container-text break-normal"
                                             [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                            {{ resumeHasEducationOutDto?.region | titlecase }}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutLeftRef>
    <div class="px-4">
        <div class="section-title-right flex flex-row items-center justify-start resume-section-title-bottom">
            <div class="flex flex-row items-center justify-start">
                <i class="w-4 ti ti-school text:sm sm:text-2xl sm:w-6"
                   [style.color]="resumeTheme.iconColor"></i>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.twoColumnHighlightedHeadingText">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.EDUCATION | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        @for (resumeHasEducationOutDto of resumeHasEducationOutDtos; let index = $index; track resumeHasEducationOutDto?.id) {
            <div class="flex flex-col items-start justify-center resume-section-content-between">
                <div [ngStyle]="{'color': resumeTheme?.primaryColor}">
                    <div class="resume-section-container-title break-normal"
                         [ngStyle]="{'color': resumeTheme?.twoColumnHighlightedPrimaryText}">
                        {{ resumeHasEducationOutDto?.degree }}
                    </div>
                    <div class="resume-section-container-text break-normal"
                         [ngStyle]="{'color': resumeTheme?.twoColumnHighlightedPrimaryText}">
                        {{ resumeHasEducationOutDto?.specialization }}
                    </div>
                    <div class="flex flex-col justify-between">
                        @if (resumeHasEducationOutDto?.startDate) {
                            <div class="flex items-center justify-start resume-section-container-text">
                                <div class="pr-1 resume-section-container-text break-normal"
                                     [ngStyle]="{'color': resumeTheme.twoColumnHighlightedPrimaryText}">
                                    {{ resumeHasEducationOutDto?.startDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                    -
                                </div>
                                <ng-container
                                    [ngTemplateOutlet]="(resumeHasEducationOutDto?.ongoing) || !resumeHasEducationOutDto?.endDate ? presentBlock : endDateBlock">
                                </ng-container>
                                <ng-template #presentBlock>
                <span [ngStyle]="{'color': resumeTheme?.twoColumnHighlightedPrimaryText}"
                      class="resume-section-container-text">Present</span>
                                </ng-template>
                                <ng-template #endDateBlock>
              <span
                  class="resume-section-container-text"
                  [ngStyle]="{'color': resumeTheme.twoColumnHighlightedPrimaryText}">
                {{ resumeHasEducationOutDto?.endDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
              </span>
                                </ng-template>
                            </div>
                        }
                        <div class="resume-section-container-text break-normal"
                             [ngStyle]="{'color': resumeTheme.twoColumnHighlightedSecondaryText}">{{ resumeHasEducationOutDto?.university }}
                        </div>
                        <div class="resume-section-container-text break-normal"
                             [ngStyle]="{'color': resumeTheme.twoColumnHighlightedSecondaryText}">{{ resumeHasEducationOutDto?.region | titlecase }}
                        </div>
                        @if (resumeHasEducationOutDto?.grade) {
                            <div class="card-badge text-blue-2000 text-xxs sm:text-xs break-normal chip"
                                 [ngStyle]="{'color': resumeTheme?.highlightedChipText, 'background': resumeTheme?.highlightedChipBackground}">
                                Grade {{ resumeHasEducationOutDto?.grade }}
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>
