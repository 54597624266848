@if (resumeHasLanguageOutDtos?.length > 0) {
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.atsLayout == ATS_LAYOUT_TYPE.CLASSIC ? classicLayoutRef : modernLayoutTemplateRef">
    </ng-container>
}

<ng-template #classicLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="resume-section-title"
                     [style.color]="resumeTheme.primaryColor">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.LANGUAGE | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="w-full grid grid-cols-2 gap-2 sm:grid-cols-3 mb-3">
            @for (resumeHasLanguageOutDto of resumeHasLanguageOutDtos; track resumeHasLanguageOutDto?.id) {
                <div class="h-full flex flex-col items-start justify-center section-card">
                    <div class="resume-section-container-title"
                         [style.color]="resumeTheme.primaryColor">
                        {{ resumeHasLanguageOutDto?.language | titlecase }}
                    </div>
                    @if (resumeHasLanguageOutDto?.languageProficiency) {
                        <div class="resume-section-container-text"
                             [style.color]="resumeTheme.secondaryColor">
                            {{ LANGUAGE_PROFICIENCY_DISPLAY[resumeHasLanguageOutDto?.languageProficiency] }}
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #modernLayoutTemplateRef>
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.layoutType === LAYOUT_TYPE.TWO_COLUMN ? twoColumnModernLayoutRef : oneColumnModernLayoutRef">
    </ng-container>
</ng-template>

<ng-template #oneColumnModernLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <i class="ti ti-language text-sm sm:text-2xl"
                   [ngStyle]="{'color': resumeTheme.iconColor}">
                </i>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.primaryColor">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.LANGUAGE | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="w-full grid grid-cols-2 gap-2 sm:grid-cols-3 mb-3">
            @for (resumeHasLanguageOutDto of resumeHasLanguageOutDtos; track resumeHasLanguageOutDto?.id) {
                <div class="h-full flex flex-col items-start justify-center section-card">
                    <div class="resume-section-container-title"
                         [style.color]="resumeTheme.primaryColor">
                        {{ resumeHasLanguageOutDto?.language | titlecase }}
                    </div>
                    @if (resumeHasLanguageOutDto?.languageProficiency) {
                        <div class="resume-section-container-text"
                             [style.color]="resumeTheme.secondaryColor">
                            {{ LANGUAGE_PROFICIENCY_DISPLAY[resumeHasLanguageOutDto?.languageProficiency] }}
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRef>
    <ng-container
        [ngTemplateOutlet]="resumeSectionDataOutDto?.columnIndex === 1 ? twoColumnModernLayoutRightRef :
        twoColumnModernLayoutLeftRef">
    </ng-container>
</ng-template>

<ng-template #twoColumnModernLayoutLeftRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <i class="ti ti-language text-sm sm:text-2xl"
                   [ngStyle]="{'color': resumeTheme.iconColor}">
                </i>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.twoColumnHighlightedHeadingText">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.LANGUAGE | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="w-full grid grid-cols-1">
            @for (resumeHasLanguageOutDto of resumeHasLanguageOutDtos; track resumeHasLanguageOutDto?.id) {
                <div class="resume-section-content-between">
                    <div class="h-full flex flex-col items-start justify-center">
                        <div class="resume-section-container-title"
                             [style.color]="resumeTheme.twoColumnHighlightedHeadingText">
                            {{ resumeHasLanguageOutDto?.language | titlecase }}
                        </div>
                        @if (resumeHasLanguageOutDto?.languageProficiency) {
                            <div class="resume-section-container-text"
                                 [style.color]="resumeTheme.twoColumnHighlightedSecondaryText">
                                {{ LANGUAGE_PROFICIENCY_DISPLAY[resumeHasLanguageOutDto?.languageProficiency] }}
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRightRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <i class="ti ti-language text-sm sm:text-2xl"
                   [ngStyle]="{'color': resumeTheme.iconColor}">
                </i>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.primaryColor">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.LANGUAGE | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="w-full grid grid-cols-2 gap-2 sm:grid-cols-3 mb-3">
            @for (resumeHasLanguageOutDto of resumeHasLanguageOutDtos; track resumeHasLanguageOutDto?.id) {
                <div class="h-full flex flex-col items-start justify-center section-card">
                    <div class="resume-section-container-title"
                         [style.color]="resumeTheme.primaryColor">
                        {{ resumeHasLanguageOutDto?.language | titlecase }}
                    </div>
                    @if (resumeHasLanguageOutDto?.languageProficiency) {
                        <div class="resume-section-container-text"
                             [style.color]="resumeTheme.secondaryColor">
                            {{ LANGUAGE_PROFICIENCY_DISPLAY[resumeHasLanguageOutDto?.languageProficiency] }}
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</ng-template>
