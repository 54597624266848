import {Component, Input, OnInit} from '@angular/core';
import {ResumeStore} from "../../../ai-resume/common/store/resume.store";
import {ResumeOutDto, ResumeSectionDataOutDto} from "../../common/resume.model";
import {Subscription} from "rxjs";

@Component({
    selector: 'tal-r-resume-branding',
    standalone: true,
    templateUrl: './r-resume-branding.component.html',
    styleUrl: './r-resume-branding.component.scss'
})
export class RResumeBrandingComponent implements OnInit {
    @Input() resumeSectionDataOutDto: ResumeSectionDataOutDto;
    resumeOutDto?: ResumeOutDto;
    defaultBrandingImageUrl = 'assets/resume/made-with-love-branding.png';
    private subscription = new Subscription();

    constructor(private resumeStore: ResumeStore) {
    }

    ngOnInit(): void {
        this.subscribeToResumeStore();
    }

    private subscribeToResumeStore(): void {
        this.subscription.add(this.resumeStore.updatedResumeOutDto
            .subscribe(resumeOutDto => {
                if (resumeOutDto) {
                    this.resumeOutDto = resumeOutDto;
                }
            }));
    }
}
