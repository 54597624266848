<ng-container>
    @if (loading) {
        <div class="m-auto resume-shimmer-effect">
            <div class="shimmer-background media"></div>
            <div class="p-8">
                <div class="shimmer-background title-line"></div>
                <div class="shimmer-background title-line end"></div>
                <div class="shimmer-background content-line mt-6"></div>
                <div class="shimmer-background content-line"></div>
                <div class="shimmer-background content-line"></div>
                <div class="shimmer-background content-line"></div>
                <div class="shimmer-background content-line end"></div>
            </div>
        </div>
    } @else {
        <ng-container
            [ngTemplateOutlet]="resumeOutDto?.atsLayout === ATS_LAYOUT_TYPE.CLASSIC ? classicTemplateRef : modernTemplateRef">
        </ng-container>
    }
</ng-container>

<ng-template #modernTemplateRef>
    <ng-container [ngTemplateOutlet]="resumeOutDto?.layoutType === LAYOUT_TYPE.TWO_COLUMN
                                      ? twoColumnModernLayoutRef
                                      : oneColumnModernLayoutRef">
    </ng-container>
</ng-template>

<ng-template #classicTemplateRef>
    <div class="flex flex-col w-full resume-width rounded-xl light-box-shadow min-h-screen h-full relative"
         [ngStyle]="{'background' : resumeTheme?.pageBackground}">
        <div class="pb-16 flex flex-col">
            @for (leftColumnResumeSectionDataOutDtoWithComponent of leftColumnResumeSectionDataOutDtoWithComponents; track
                leftColumnResumeSectionDataOutDtoWithComponent?.resumeSectionDataOutDto?.id; let index = $index) {
                @if (leftColumnResumeSectionDataOutDtoWithComponent?.component) {
                    <ng-container
                        *ngComponentOutlet="leftColumnResumeSectionDataOutDtoWithComponent?.component;
                        inputs: { resumeSectionDataOutDto : leftColumnResumeSectionDataOutDtoWithComponent?.resumeSectionDataOutDto }">
                    </ng-container>
                }
            }
        </div>
        <div class="w-fit absolute bottom-2 right-2">
            <tal-r-resume-branding>
            </tal-r-resume-branding>
        </div>
    </div>
</ng-template>

<ng-template #oneColumnModernLayoutRef>
    <div class="flex flex-col w-full resume-width rounded-xl light-box-shadow min-h-screen h-full relative"
         [ngStyle]="{'background' : resumeTheme?.pageBackground}">
        <div class="pb-16 flex flex-col">
            @for (leftColumnResumeSectionDataOutDtoWithComponent of leftColumnResumeSectionDataOutDtoWithComponents; track
                leftColumnResumeSectionDataOutDtoWithComponent?.resumeSectionDataOutDto?.id; let index = $index) {
                @if (leftColumnResumeSectionDataOutDtoWithComponent?.component) {
                    <ng-container
                        *ngComponentOutlet="leftColumnResumeSectionDataOutDtoWithComponent?.component;
                        inputs: { resumeSectionDataOutDto : leftColumnResumeSectionDataOutDtoWithComponent?.resumeSectionDataOutDto }">
                    </ng-container>
                }
            }
        </div>
        <div class="w-fit absolute bottom-2 right-2">
            <tal-r-resume-branding>
            </tal-r-resume-branding>
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRef>
    <div class="flex relative max-width-screen sm:w-full rounded-xl resume-width light-box-shadow"
         [ngStyle]="{'background' : resumeTheme?.pageBackground}">
        <!--    Note : left section-->
        <div class="resume-left-section left-section-border-radius"
             style="border-right: 1px solid var(--color-light-white-gray)"
             [ngStyle]="{'background' : resumeTheme?.highlightedBackground}">
            @if (resumeOutDto?.personalInformation?.imageUrl) {
                <div class="px-4 pt-4">
                    <img class="w-full fade-in rounded-3xl mb-3"
                         alt="profileImage"
                         [src]="resumeOutDto?.personalInformation?.imageUrl"/>
                </div>
            }
            @for (leftColumnResumeSectionDataOutDtoWithComponent of leftColumnResumeSectionDataOutDtoWithComponents; track
                leftColumnResumeSectionDataOutDtoWithComponent?.resumeSectionDataOutDto?.id) {
                @if (leftColumnResumeSectionDataOutDtoWithComponent?.component != null) {
                    <ng-container
                        *ngComponentOutlet="leftColumnResumeSectionDataOutDtoWithComponent?.component;
                        inputs: { resumeSectionDataOutDto : leftColumnResumeSectionDataOutDtoWithComponent?.resumeSectionDataOutDto }">
                    </ng-container>
                }
            }
        </div>
        <!--    Note : right section-->
        <div class="flex flex-col right-side-top-container">
            <div class="pb-16 flex flex-col">
                @for (rightColumnResumeSectionDataOutDtoWithComponent of rightColumnResumeSectionDataOutDtoWithComponents; track
                    rightColumnResumeSectionDataOutDtoWithComponent?.resumeSectionDataOutDto?.id; let index = $index) {
                    @if (rightColumnResumeSectionDataOutDtoWithComponent?.component) {
                        <ng-container
                            *ngComponentOutlet="rightColumnResumeSectionDataOutDtoWithComponent?.component;
                                inputs: { resumeSectionDataOutDto : rightColumnResumeSectionDataOutDtoWithComponent?.resumeSectionDataOutDto}">
                        </ng-container>
                    }
                }
            </div>
            <div class="w-fit absolute bottom-2 right-2">
                <tal-r-resume-branding>
                </tal-r-resume-branding>
            </div>
        </div>
    </div>
</ng-template>
