@if (resumeHasReferenceOutDtos?.length > 0) {
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.atsLayout == ATS_LAYOUT_TYPE.CLASSIC ? classicLayoutRef : modernLayoutTemplateRef">
    </ng-container>
}

<ng-template #classicLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="flex flex-row items-center gap-2 resume-section-title"
                     [ngStyle]="{'color': resumeTheme.primaryColor}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.REFERENCE | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div>
            @for (resumeHasReferenceOutDto of resumeHasReferenceOutDtos; track resumeHasReferenceOutDto?.id) {
                <div
                    class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                    <div class="w-full">
                        <div class="flex items-center justify-start">
                            <div class="resume-section-container-title"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                {{ resumeHasReferenceOutDto?.name | titlecase }}
                            </div>
                            @if (resumeHasReferenceOutDto?.linkedInUrl) {
                                <div class="ml-2 resume-section-container-text color-primary cursor-pointer"
                                     (click)="openUrlInNewTab(resumeHasReferenceOutDto?.linkedInUrl)">
                                    Linkedin URL
                                </div>
                            }
                        </div>
                        <div class="resume-section-container-text"
                             [ngStyle]="{'color': resumeTheme?.secondaryColor}">
                            {{ resumeHasReferenceOutDto?.jobTitle | titlecase }}
                            @if (resumeHasReferenceOutDto?.jobTitle && resumeHasReferenceOutDto?.companyName) {
                                &#64;
                            }
                            @if (resumeHasReferenceOutDto?.companyName) {
                                <span class="resume-section-container-text"
                                      [ngStyle]="{'color': resumeTheme?.secondaryColor}">
                            {{ resumeHasReferenceOutDto?.companyName }}
                            </span>
                            }
                        </div>
                        <div class="flex flex-row items-center justify-start">
                            <div class="resume-section-container-text pl-1"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                {{ resumeHasReferenceOutDto?.phoneNumber }}
                            </div>
                            <div class="text-sm pl-2" [ngClass]="resumeTheme?.primaryColor"> |</div>
                            <div class="resume-section-container-text pl-1"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                <!--email_off-->{{ resumeHasReferenceOutDto?.emailAddress }}<!--/email_off-->
                            </div>
                        </div>
                        @if (resumeHasReferenceOutDto?.description) {
                            <div class="resume-section-container-description"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}"
                                 [innerHTML]="resumeHasReferenceOutDto?.description">
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #modernLayoutTemplateRef>
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.layoutType === LAYOUT_TYPE.TWO_COLUMN ? twoColumnModernLayoutRef : oneColumnModernLayoutRef">
    </ng-container>
</ng-template>

<ng-template #oneColumnModernLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="ti ti-users text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                </div>
                <div class="flex flex-row items-center gap-2 resume-section-title pl-1"
                     [ngStyle]="{'color': resumeTheme.primaryColor}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.REFERENCE | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div>
            @for (resumeHasReferenceOutDto of resumeHasReferenceOutDtos; track resumeHasReferenceOutDto?.id) {
                <div
                    class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                    <div class="w-full">
                        <div class="flex items-center justify-start">
                            <div class="resume-section-container-title"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                {{ resumeHasReferenceOutDto?.name | titlecase }}
                            </div>
                            @if (resumeHasReferenceOutDto?.linkedInUrl) {
                                <div class="text-xxs sm:text-sm px-1 ti ti-point-filled"
                                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                                </div>
                            }
                            @if (resumeHasReferenceOutDto?.linkedInUrl) {
                                <div class="text-sm sm:text-xl ti ti-brand-linkedin cursor-pointer"
                                     [ngStyle]="{'color': resumeTheme?.iconColor}"
                                     (click)="openUrlInNewTab(resumeHasReferenceOutDto?.linkedInUrl)">
                                </div>
                            }
                        </div>
                        <div class="resume-section-container-text"
                             [ngStyle]="{'color': resumeTheme?.secondaryColor}">
                            {{ resumeHasReferenceOutDto?.jobTitle | titlecase }}
                            @if (resumeHasReferenceOutDto?.jobTitle && resumeHasReferenceOutDto?.companyName) {
                                &#64;
                            }
                            @if (resumeHasReferenceOutDto?.companyName) {
                                <span class="resume-section-container-text"
                                      [ngStyle]="{'color': resumeTheme?.secondaryColor}">
              {{ resumeHasReferenceOutDto?.companyName }}
                          </span>
                            }
                        </div>
                        <div class="flex flex-row items-center justify-start">
                            @if (resumeHasReferenceOutDto?.phoneNumber) {
                                <div class="font-normal text-xxs sm:text-sm ti ti-phone pr-1"
                                     [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                </div>
                            }
                            <div class="resume-section-container-text pl-1"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                {{ resumeHasReferenceOutDto?.phoneNumber }}
                            </div>
                            @if (resumeHasReferenceOutDto?.emailAddress) {
                                <div class="font-normal text-xxs sm:text-sm ti ti-mail"
                                     [ngClass]="resumeHasReferenceOutDto?.phoneNumber ? 'pl-4' : ''"
                                     [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                </div>
                            }
                            <div class="resume-section-container-text pl-1"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                <!--email_off-->{{ resumeHasReferenceOutDto?.emailAddress }}<!--/email_off-->
                            </div>
                        </div>
                        @if (resumeHasReferenceOutDto?.description) {
                            <div class="resume-section-container-description"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}"
                                 [innerHTML]="resumeHasReferenceOutDto?.description">
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRef>
    <ng-container
        [ngTemplateOutlet]="resumeSectionDataOutDto?.columnIndex === 1 ? twoColumnModernLayoutRightRef :
        twoColumnModernLayoutLeftRef">
    </ng-container>
</ng-template>

<ng-template #twoColumnModernLayoutRightRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="ti ti-users text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                </div>
                <div class="flex flex-row items-center gap-2 resume-section-title pl-1"
                     [ngStyle]="{'color': resumeTheme.primaryColor}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.REFERENCE | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div>
            @for (resumeHasReferenceOutDto of resumeHasReferenceOutDtos; track resumeHasReferenceOutDto?.id) {
                <div
                    class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                    <div class="w-full">
                        <div class="flex items-center justify-start">
                            <div class="resume-section-container-title"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                {{ resumeHasReferenceOutDto?.name | titlecase }}
                            </div>
                            @if (resumeHasReferenceOutDto?.linkedInUrl) {
                                <div class="text-xxs sm:text-sm px-1 ti ti-point-filled"
                                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                                </div>
                            }
                            @if (resumeHasReferenceOutDto?.linkedInUrl) {
                                <div class="text-sm sm:text-xl ti ti-brand-linkedin cursor-pointer"
                                     [ngStyle]="{'color': resumeTheme?.iconColor}"
                                     (click)="openUrlInNewTab(resumeHasReferenceOutDto?.linkedInUrl)">
                                </div>
                            }
                        </div>
                        <div class="resume-section-container-text"
                             [ngStyle]="{'color': resumeTheme?.secondaryColor}">
                            {{ resumeHasReferenceOutDto?.jobTitle | titlecase }}
                            @if (resumeHasReferenceOutDto?.jobTitle && resumeHasReferenceOutDto?.companyName) {
                                &#64;
                            }
                            @if (resumeHasReferenceOutDto?.companyName) {
                                <span class="resume-section-container-text"
                                      [ngStyle]="{'color': resumeTheme?.secondaryColor}">
              {{ resumeHasReferenceOutDto?.companyName }}
                          </span>
                            }
                        </div>
                        <div class="flex flex-row items-center justify-start">
                            @if (resumeHasReferenceOutDto?.phoneNumber) {
                                <div class="font-normal text-xxs sm:text-sm ti ti-phone pr-1"
                                     [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                </div>
                            }
                            <div class="resume-section-container-text pl-1"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                {{ resumeHasReferenceOutDto?.phoneNumber }}
                            </div>
                            @if (resumeHasReferenceOutDto?.emailAddress) {
                                <div class="font-normal text-xxs sm:text-sm ti ti-mail"
                                     [ngClass]="resumeHasReferenceOutDto?.phoneNumber ? 'pl-4' : ''"
                                     [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                </div>
                            }
                            <div class="resume-section-container-text pl-1"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                <!--email_off-->{{ resumeHasReferenceOutDto?.emailAddress }}<!--/email_off-->
                            </div>
                        </div>
                        @if (resumeHasReferenceOutDto?.description) {
                            <div class="resume-section-container-description"
                                 [ngStyle]="{'color': resumeTheme?.primaryColor}"
                                 [innerHTML]="resumeHasReferenceOutDto?.description">
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutLeftRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="ti ti-users text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                </div>
                <div class="flex flex-row items-center gap-2 resume-section-title pl-1"
                     [ngStyle]="{'color': resumeTheme.twoColumnHighlightedHeadingText}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.REFERENCE | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div>
            @for (resumeHasReferenceOutDto of resumeHasReferenceOutDtos; track resumeHasReferenceOutDto?.id) {
                <div
                    class="w-full flex flex-col items-center justify-center resume-section-content-between">
                    <div class="w-full">
                        <div class="flex items-center justify-start">
                            <div class="resume-section-container-title"
                                 [ngStyle]="{'color': resumeTheme?.twoColumnHighlightedHeadingText}">
                                {{ resumeHasReferenceOutDto?.name | titlecase }}
                            </div>
                            @if (resumeHasReferenceOutDto?.linkedInUrl) {
                                <div class="text-xxs sm:text-sm px-1 ti ti-point-filled"
                                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                                </div>
                            }
                            @if (resumeHasReferenceOutDto?.linkedInUrl) {
                                <div class="text-sm sm:text-xl ti ti-brand-linkedin cursor-pointer"
                                     [ngStyle]="{'color': resumeTheme?.iconColor}"
                                     (click)="openUrlInNewTab(resumeHasReferenceOutDto?.linkedInUrl)">
                                </div>
                            }
                        </div>
                        <div class="resume-section-container-text"
                             [ngStyle]="{'color': resumeTheme?.secondaryColor}">
                            {{ resumeHasReferenceOutDto?.jobTitle | titlecase }}
                            @if (resumeHasReferenceOutDto?.jobTitle && resumeHasReferenceOutDto?.companyName) {
                                &#64;
                            }
                            @if (resumeHasReferenceOutDto?.companyName) {
                                <span class="resume-section-container-text"
                                      [ngStyle]="{'color': resumeTheme?.secondaryColor}">
              {{ resumeHasReferenceOutDto?.companyName }}
                          </span>
                            }
                        </div>
                        <div class="flex flex-col items-start justify-start">
                            @if (resumeHasReferenceOutDto?.phoneNumber) {
                                <div class="flex">
                                    <div class="font-normal text-xxs sm:text-sm ti ti-phone pr-1"
                                         [ngStyle]="{'color': resumeTheme?.twoColumnHighlightedHeadingText}">
                                    </div>
                                    <div class="resume-section-container-text pl-1"
                                         [ngStyle]="{'color': resumeTheme?.twoColumnHighlightedHeadingText}">
                                        {{ resumeHasReferenceOutDto?.phoneNumber }}
                                    </div>
                                </div>
                            }
                            @if (resumeHasReferenceOutDto?.emailAddress) {
                                <div class="flex">
                                    <div class="font-normal text-xxs sm:text-sm ti ti-mail"
                                         [ngStyle]="{'color': resumeTheme?.twoColumnHighlightedHeadingText}">
                                    </div>
                                    <div class="resume-section-container-text pl-1"
                                         [ngStyle]="{'color': resumeTheme?.twoColumnHighlightedHeadingText}">
                                        <!--email_off-->{{ resumeHasReferenceOutDto?.emailAddress }}<!--/email_off-->
                                    </div>
                                </div>
                            }
                        </div>
                        @if (resumeHasReferenceOutDto?.description) {
                            <div class="resume-section-container-description"
                                 [ngStyle]="{'color': resumeTheme?.twoColumnHighlightedHeadingText}"
                                 [innerHTML]="resumeHasReferenceOutDto?.description">
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>
