import {Component, Input, OnInit} from '@angular/core';
import {DatePipe, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {ResumeHasEducationOutDto} from "../../common/resume-section-model/education.model";
import {ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {DateFormatter} from "../../../../common/util/date.util";
import {ATS_LAYOUT_TYPE, LAYOUT_TYPE} from "../../common/layout-selection.model";
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {SelectThemeUtil} from "../../common/select-theme.util";
import {ResumeStore} from "../../../ai-resume/common/store/resume.store";
import {Subscription} from "rxjs";
import {ResumeOutDto, ResumeSectionDataOutDto} from "../../common/resume.model";

@Component({
    selector: 'tal-r-education',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        TitleCasePipe,
        NgStyle,
        DatePipe
    ],
    templateUrl: './r-education.component.html',
    styleUrl: './r-education.component.scss'
})
export class REducationComponent implements OnInit {
    @Input() resumeSectionDataOutDto: ResumeSectionDataOutDto;

    resumeOutDto?: ResumeOutDto;
    resumeTheme?: ResumeThemeModel;
    resumeHasEducationOutDtos: ResumeHasEducationOutDto[];

    protected readonly DateFormatter = DateFormatter;
    protected readonly LAYOUT_TYPE = LAYOUT_TYPE;
    protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
    protected readonly ATS_LAYOUT_TYPE = ATS_LAYOUT_TYPE;

    private subscription = new Subscription();

    constructor(private resumeStore: ResumeStore) {
    }

    ngOnInit(): void {
        this.subscribeToResumeStore();
    }

    private subscribeToResumeStore(): void {
        this.subscription.add(this.resumeStore.updatedResumeOutDto
            .subscribe(resumeOutDto => {
                if (resumeOutDto) {
                    this.resumeOutDto = resumeOutDto;
                    this.resumeHasEducationOutDtos = resumeOutDto?.educations;
                    this.setResumeTheme();
                }
            }));
    }

    private setResumeTheme(): void {
        this.resumeTheme = SelectThemeUtil.setResumeTheme(this.resumeOutDto.atsLayout === ATS_LAYOUT_TYPE.CLASSIC, this.resumeOutDto?.layoutType, this.resumeOutDto?.theme);
    }
}
