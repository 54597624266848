import {Component, Input, OnInit} from '@angular/core';
import {NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {ResumeIconModel} from "../../common/resume-icon.model";
import {ATS_LAYOUT_TYPE, LAYOUT_TYPE} from "../../common/layout-selection.model";
import {ResumeHasAchievementOutDto} from "../../common/resume-section-model/achievement.model";
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {LinkService} from "../../../../common/util/link.service";
import {ResumeOutDto, ResumeSectionDataOutDto} from "../../common/resume.model";
import {ResumeStore} from "../../../ai-resume/common/store/resume.store";
import {Subscription} from "rxjs";
import {SelectThemeUtil} from "../../common/select-theme.util";

@Component({
    selector: 'tal-r-achievement',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NgStyle,
        NgClass,
        TitleCasePipe
    ],
    templateUrl: './r-achievement.component.html',
    styleUrl: './r-achievement.component.scss'
})
export class RAchievementComponent implements OnInit {
    @Input() resumeSectionDataOutDto: ResumeSectionDataOutDto;

    resumeOutDto?: ResumeOutDto;
    resumeThemeModel?: ResumeThemeModel;
    resumeHasAchievementOutDtos: ResumeHasAchievementOutDto[];

    ResumeIconModel = ResumeIconModel;

    protected readonly LAYOUT_TYPE = LAYOUT_TYPE;
    protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
    protected readonly ATS_LAYOUT_TYPE = ATS_LAYOUT_TYPE;

    private subscription = new Subscription();

    constructor(private resumeStore: ResumeStore,
                private linkService: LinkService) {
    }

    ngOnInit(): void {
        this.subscribeToResumeStore();
    }

    openLinkInNewTab(link: string): void {
        this.linkService.openLinkInNewTab(link);
    }

    private subscribeToResumeStore(): void {
        this.subscription.add(this.resumeStore.updatedResumeOutDto
            .subscribe(resumeOutDto => {
                if (resumeOutDto) {
                    this.resumeOutDto = resumeOutDto;
                    this.resumeHasAchievementOutDtos = resumeOutDto?.achievements;
                    this.setResumeTheme();
                }
            }));
    }

    private setResumeTheme(): void {
        this.resumeThemeModel = SelectThemeUtil.setResumeTheme(this.resumeOutDto.atsLayout === ATS_LAYOUT_TYPE.CLASSIC, this.resumeOutDto?.layoutType, this.resumeOutDto?.theme);
    }
}
