@if (resumeHasCertificateOutDtos?.length > 0) {
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.atsLayout == ATS_LAYOUT_TYPE.CLASSIC ? classicLayoutRef : modernLayoutTemplateRef">
    </ng-container>
}

<ng-template #classicLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex flex-row items-center justify-start">
                <div class="resume-section-title"
                     [style.color]="resumeTheme.primaryColor">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.CERTIFICATES | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="section-description">
            <div class="w-full">
                @for (resumeHasCertificateOutDto of resumeHasCertificateOutDtos; let index = $index; track resumeHasCertificateOutDto?.id) {
                    <div
                        class="my-2 sm:mb-4 w-full flex flex-col items-start justify-center resume-section-content-between section-card">
                        <div class="w-full">
                            <div class="flex flex-row items-start justify-between">
                                <div class="w-3/5 break-all flex flex-col isolate justify-between">
                                    <div class="flex flex-row items-center justify-start">
                                        <div class="resume-section-container-title break-normal"
                                             [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                            {{ resumeHasCertificateOutDto?.name }}
                                        </div>
                                    </div>
                                    <div [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                         class="resume-section-container-text flex flex-row items-start">
                                        <div
                                            class="max-w-96 w-fit break-normal">{{ resumeHasCertificateOutDto?.certifiedBy }}
                                        </div>
                                        @if (resumeHasCertificateOutDto?.university && resumeHasCertificateOutDto?.certifiedBy) {
                                            <div class="mx-0.5">|</div>
                                        }
                                        <div class="break-normal">{{ resumeHasCertificateOutDto?.university }}</div>
                                    </div>
                                </div>
                                <div
                                    class="text-xs sm:text-sm w-2/5 break-all ml-4 flex flex-col items-end justify-between">
                                    @if (resumeHasCertificateOutDto?.certificationDate) {
                                        <div [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                             class="resume-section-container-text flex flex-row break-normal">
                                            <div class="pr-1 section-sub-title">
                                                {{ resumeHasCertificateOutDto?.certificationDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                            </div>
                                            @if (resumeHasCertificateOutDto?.expirationDate) {
                                                <div class="section-sub-title">
                                                    <span>-</span>
                                                    {{ resumeHasCertificateOutDto?.expirationDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                                </div>
                                            }
                                        </div>
                                    }
                                    @if (resumeHasCertificateOutDto?.region) {
                                        <div class="resume-section-container-text break-normal"
                                             [ngStyle]="{'color': resumeTheme?.secondaryColor}">
                                            {{ resumeHasCertificateOutDto?.region | titlecase }}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        @if (resumeHasCertificateOutDto?.link) {
                            <div class="resume-section-container-text color-primary"
                                 (click)="openUrlInNewTab(resumeHasCertificateOutDto?.link)">
                                Link
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #oneColumnModernLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex flex-row items-center justify-start">
                <div class="ti ti-book text-sm sm:text-2xl"
                     [style.color]="resumeTheme?.iconColor">
                </div>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.primaryColor">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.CERTIFICATES | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="section-description">
            <div class="w-full">
                @for (resumeHasCertificateOutDto of resumeHasCertificateOutDtos; let index = $index; track resumeHasCertificateOutDto?.id) {
                    <div
                        class="my-2 sm:mb-4 w-full flex flex-col items-start justify-center resume-section-content-between section-card">
                        <div class="w-full">
                            <div class="flex flex-row items-start justify-between">
                                <div class="w-3/5 break-all flex flex-col isolate justify-between">
                                    <div class="flex flex-row items-center justify-start">
                                        <div class="resume-section-container-title break-normal"
                                             [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                            {{ resumeHasCertificateOutDto?.name }}
                                        </div>
                                        @if (resumeHasCertificateOutDto?.link) {
                                            <div
                                                class="ti ti-external-link text-sm sm:text-xl leading-none pl-2 cursor-pointer"
                                                [style.color]="resumeTheme?.iconColor"
                                                (click)="openUrlInNewTab(resumeHasCertificateOutDto.link)">
                                            </div>
                                        }
                                    </div>
                                    <div [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                         class="resume-section-container-text flex flex-row items-start">
                                        <div
                                            class="max-w-96 w-fit break-normal">{{ resumeHasCertificateOutDto?.certifiedBy }}
                                        </div>
                                        @if (resumeHasCertificateOutDto?.university && resumeHasCertificateOutDto?.certifiedBy) {
                                            <div class="mx-0.5">|</div>
                                        }
                                        <div class="break-normal">{{ resumeHasCertificateOutDto?.university }}</div>
                                    </div>
                                </div>
                                <div
                                    class="text-xs sm:text-sm w-2/5 break-all ml-4 flex flex-col items-end justify-between">
                                    @if (resumeHasCertificateOutDto?.certificationDate) {
                                        <div [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                             class="resume-section-container-text flex flex-row break-normal">
                                            <div class="pr-1 section-sub-title">
                                                {{ resumeHasCertificateOutDto?.certificationDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                            </div>
                                            @if (resumeHasCertificateOutDto?.expirationDate) {
                                                <div class="section-sub-title">
                                                    <span>-</span>
                                                    {{ resumeHasCertificateOutDto?.expirationDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                                </div>
                                            }
                                        </div>
                                    }
                                    @if (resumeHasCertificateOutDto?.region) {
                                        <div class="resume-section-container-text break-normal"
                                             [ngStyle]="{'color': resumeTheme?.secondaryColor}">
                                            {{ resumeHasCertificateOutDto?.region | titlecase }}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modernLayoutTemplateRef>
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.layoutType === LAYOUT_TYPE.TWO_COLUMN ? twoColumnModernLayoutRef : oneColumnModernLayoutRef">
    </ng-container>
</ng-template>
<ng-template #twoColumnModernLayoutRef>
    <ng-container
        [ngTemplateOutlet]="resumeSectionDataOutDto?.columnIndex === 1 ? twoColumnModernLayoutRightRef :
        twoColumnModernLayoutLeftRef">
    </ng-container>
</ng-template>

<ng-template #twoColumnModernLayoutRightRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex flex-row items-center justify-start">
                <div class="ti ti-book text-sm sm:text-2xl"
                     [style.color]="resumeTheme?.iconColor">
                </div>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.primaryColor">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.CERTIFICATES | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="section-description">
            <div class="w-full">
                @for (resumeHasCertificateOutDto of resumeHasCertificateOutDtos; let index = $index; track resumeHasCertificateOutDto?.id) {
                    <div
                        class="my-2 sm:mb-4 w-full flex flex-col items-start justify-center resume-section-content-between section-card">
                        <div class="w-full">
                            <div class="flex flex-row items-start justify-between">
                                <div class="w-3/5 break-all flex flex-col isolate justify-between">
                                    <div class="flex flex-row items-center justify-start">
                                        <div class="resume-section-container-title break-normal"
                                             [ngStyle]="{'color': resumeTheme?.primaryColor}">
                                            {{ resumeHasCertificateOutDto?.name }}
                                        </div>
                                        @if (resumeHasCertificateOutDto?.link) {
                                            <div
                                                class="ti ti-external-link text-sm sm:text-xl leading-none pl-2 cursor-pointer"
                                                [style.color]="resumeTheme?.iconColor"
                                                (click)="openUrlInNewTab(resumeHasCertificateOutDto.link)">
                                            </div>
                                        }
                                    </div>
                                    <div [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                         class="resume-section-container-text flex flex-row items-start">
                                        <div
                                            class="max-w-96 w-fit break-normal">{{ resumeHasCertificateOutDto?.certifiedBy }}
                                        </div>
                                        @if (resumeHasCertificateOutDto?.university && resumeHasCertificateOutDto?.certifiedBy) {
                                            <div class="mx-0.5">|</div>
                                        }
                                        <div class="break-normal">{{ resumeHasCertificateOutDto?.university }}</div>
                                    </div>
                                </div>
                                <div
                                    class="text-xs sm:text-sm w-2/5 break-all ml-4 flex flex-col items-end justify-between">
                                    @if (resumeHasCertificateOutDto?.certificationDate) {
                                        <div [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                             class="resume-section-container-text flex flex-row break-normal">
                                            <div class="pr-1 section-sub-title">
                                                {{ resumeHasCertificateOutDto?.certificationDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                            </div>
                                            @if (resumeHasCertificateOutDto?.expirationDate) {
                                                <div class="section-sub-title">
                                                    <span>-</span>
                                                    {{ resumeHasCertificateOutDto?.expirationDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                                </div>
                                            }
                                        </div>
                                    }
                                    @if (resumeHasCertificateOutDto?.region) {
                                        <div class="resume-section-container-text break-normal"
                                             [ngStyle]="{'color': resumeTheme?.secondaryColor}">
                                            {{ resumeHasCertificateOutDto?.region | titlecase }}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>
<ng-template #twoColumnModernLayoutLeftRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex flex-row items-center justify-start">
                <div class="ti ti-book text-sm sm:text-2xl"
                     [style.color]="resumeTheme?.iconColor">
                </div>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.twoColumnHighlightedHeadingText">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.CERTIFICATES | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        @for (resumeHasCertificateOutDto of resumeHasCertificateOutDtos; let index = $index; track resumeHasCertificateOutDto?.id) {
            <div class="flex flex-col items-start justify-center w-full resume-section-content-between">
                <div [style.color]="resumeTheme.twoColumnHighlightedPrimaryText">
                    <div class="resume-section-container-title break-normal">
                        {{ resumeHasCertificateOutDto?.name }}
                    </div>
                    <div [style.color]="resumeTheme.twoColumnHighlightedPrimaryText"
                         class="flex flex-col justify-between text-xxs sm:text-sm">
                        @if (resumeHasCertificateOutDto?.certificationDate) {
                            <div class="flex flex-row items-center justify-start break-normal">
                                <div class="pr-1 resume-section-container-text">
                                    {{ resumeHasCertificateOutDto?.certificationDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                </div>
                                @if (resumeHasCertificateOutDto?.expirationDate) {
                                    <div class="resume-section-container-text">
                                        <span>-</span>
                                        {{ resumeHasCertificateOutDto?.expirationDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                    </div>
                                }
                            </div>
                        }
                        <div [style.color]="resumeTheme.twoColumnHighlightedSecondaryText">
                            <div
                                class="resume-section-container-text break-normal">{{ resumeHasCertificateOutDto?.certifiedBy | titlecase }}
                            </div>
                            <div
                                class="resume-section-container-text break-normal">{{ resumeHasCertificateOutDto?.region | titlecase }}
                            </div>
                            <div
                                class="resume-section-container-text break-normal">{{ resumeHasCertificateOutDto?.university }}
                            </div>
                            @if (resumeHasCertificateOutDto?.link) {
                                <div class="flex flex-row items-center justify-start">
                                    <div class="ti ti-link text-xs sm:text-lg rotate-45"
                                         [ngStyle]="{'color': resumeTheme.iconColor}"></div>
                                    <div (click)="openUrlInNewTab(resumeHasCertificateOutDto?.link)"
                                         [ngStyle]="{'color': resumeTheme.iconColor}"
                                         class="cursor-pointer resume-section-container-text font-medium pl-2">
                                        Certificate
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>
