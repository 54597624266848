import {Component, Input} from '@angular/core';
import {DatePipe, NgStyle, NgTemplateOutlet, TitleCasePipe} from '@angular/common';
import {ATS_LAYOUT_TYPE, LAYOUT_TYPE} from '../../common/layout-selection.model';
import {DateFormatter} from '../../../../common/util/date.util';
import {ResumeHasCertificateOutDto} from '../../common/resume-section-model/certificate.model';
import {ResumeThemeModel} from '../../common/theme/resume-theme.model';
import {RESUME_SECTION_TYPE_DISPLAY} from '../../common/resume-section.model';
import {LinkService} from '../../../../common/util/link.service';
import {SelectThemeUtil} from "../../common/select-theme.util";
import {Subscription} from "rxjs";
import {ResumeStore} from "../../../ai-resume/common/store/resume.store";
import {ResumeOutDto, ResumeSectionDataOutDto} from "../../common/resume.model";

@Component({
    selector: 'tal-r-certificate',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        TitleCasePipe,
        NgStyle,
        DatePipe
    ],
    templateUrl: './r-certificate.component.html',
    styleUrl: './r-certificate.component.scss'
})
export class RCertificateComponent {
    @Input() resumeSectionDataOutDto: ResumeSectionDataOutDto;
    resumeOutDto?: ResumeOutDto;
    resumeTheme?: ResumeThemeModel;
    resumeHasCertificateOutDtos: ResumeHasCertificateOutDto[];

    protected readonly LAYOUT_TYPE = LAYOUT_TYPE;
    protected readonly DateFormatter = DateFormatter;
    protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
    protected readonly ATS_LAYOUT_TYPE = ATS_LAYOUT_TYPE;

    private subscription = new Subscription();

    constructor(private resumeStore: ResumeStore,
                private linkService: LinkService) {
    }

    ngOnInit(): void {
        this.subscribeToResumeStore();
    }

    openUrlInNewTab(link: any): void {
        this.linkService.openLinkInNewTab(link);
    }

    private subscribeToResumeStore(): void {
        this.subscription.add(this.resumeStore.updatedResumeOutDto
            .subscribe(resumeOutDto => {
                if (resumeOutDto) {
                    this.resumeOutDto = resumeOutDto;
                    this.resumeHasCertificateOutDtos = resumeOutDto?.certificates;
                    this.setResumeTheme();
                }
            }));
    }

    private setResumeTheme(): void {
        this.resumeTheme = SelectThemeUtil.setResumeTheme(this.resumeOutDto.atsLayout === ATS_LAYOUT_TYPE.CLASSIC, this.resumeOutDto?.layoutType, this.resumeOutDto?.theme);
    }
}
