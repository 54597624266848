import {ResumeHasPersonalInformationOutDto} from "./resume-section-model/personal-information.model";
import {ResumeHasSummaryOutDto} from "./resume-section-model/summary.model";
import {ResumeHasCertificateOutDto} from "./resume-section-model/certificate.model";
import {ResumeHasEducationOutDto} from "./resume-section-model/education.model";
import {ResumeHasHobbyOutDto} from "./resume-section-model/hobby.model";
import {ResumeHasProjectOutDto} from "./resume-section-model/project.model";
import {ResumeHasReferenceOutDto} from "./resume-section-model/reference.model";
import {ResumeHasSkillOutDto} from "./resume-section-model/skill.model";
import {ResumeHasSocialLinkOutDto} from "./resume-section-model/social-link.model";
import {ResumeHasWorkExperienceOutDto} from "./resume-section-model/work-experience.model";
import {ResumeHasAchievementOutDto} from "./resume-section-model/achievement.model";
import {RESUME_SECTION_TYPE} from "./resume-section.model";
import {ATS_LAYOUT_TYPE, LAYOUT_TYPE} from "./layout-selection.model";
import {RESUME_TEMPLATE_TYPE} from "./resume-template-type.model";
import {ResumeHasLanguageOutDto} from "./resume-section-model/language.model";

export class ResumeCommonDto {
  name: string;
  title: string;
  isDefault: boolean;
  template: RESUME_TEMPLATE_TYPE;
  theme: RESUME_THEME_TYPE;
  urlHandle?: string;
  layoutType?: LAYOUT_TYPE;
}


export class ResumeSectionDataCommonDto {
  title?: string;
  rowIndex?: number;
  columnIndex?: number;
  resumeSectionType?: RESUME_SECTION_TYPE;
  active?: boolean;
}

export class ResumeSectionDataOutDto extends ResumeSectionDataCommonDto {
  id?: string;
  createdAt?: Date;
  lastModifiedAt?: Date;
}

export class ResumeSectionDataInDto extends ResumeSectionDataCommonDto {
}

export class ResumeOutDto extends ResumeCommonDto {
  id: string;
  atsLayout: ATS_LAYOUT_TYPE;
  createdAt: Date;
  lastModifiedAt: Date;
  removeBranding: boolean;
  resumeScore: number;
  personalInformation?: ResumeHasPersonalInformationOutDto;
  summary?: ResumeHasSummaryOutDto;
  certificates?: ResumeHasCertificateOutDto[];
  educations?: ResumeHasEducationOutDto[];
  hobbies?: ResumeHasHobbyOutDto[];
  languages?: ResumeHasLanguageOutDto[];
  projects?: ResumeHasProjectOutDto[];
  references?: ResumeHasReferenceOutDto[];
  skills?: ResumeHasSkillOutDto[];
  socialLinks?: ResumeHasSocialLinkOutDto[];
  workExperiences?: ResumeHasWorkExperienceOutDto[];
  achievements?: ResumeHasAchievementOutDto[];
  resumeSectionDataOutDtos: ResumeSectionDataOutDto[];
  brandingImageUrl: string;
  // Below field is only for frontend
  isSelected = false;
}

export class ResumeMinOutDto extends ResumeCommonDto {
  id: string;
  createdAt: Date;
  lastModifiedAt: Date;
}

export enum RESUME_THEME_TYPE {
  ISTANBUL = 'ISTANBUL',
  BERLIN = 'BERLIN',
  PUNE = 'PUNE',
  TOKYO = 'TOKYO',
  MUMBAI = 'MUMBAI',
  PARIS = 'PARIS',
  NAIROBI = 'NAIROBI',
  MIAMI = 'MIAMI'
}

export enum RESUME_THEME_TYPE_DISPLAY {
  ISTANBUL = 'Istanbul',
  BERLIN = 'Berlin',
  PUNE = 'Pune',
  TOKYO = 'Tokyo',
  MUMBAI = 'Mumbai',
  PARIS = 'Paris',
  NAIROBI = 'Nairobi',
  MIAMI = 'Miami'
}
