import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientWrapperService} from '../../../service/http-client-wrapper.service';
import {RequestParam} from '../../../common/model/request-param';
import {RESUME_THEME_TYPE, ResumeOutDto} from '../common/resume.model';
import {RESUME_TEMPLATE_TYPE} from '../common/resume-template-type.model';
import {ATS_LAYOUT_TYPE, LAYOUT_TYPE} from '../common/layout-selection.model';


@Injectable({
    providedIn: 'root',
})


export class PublicApiResumeService {

    private resumeUrl = '/resumes';
    private searchUrl = '/search';
    private publicApiUrl = '/public';
    private talentUrl = '/talents';

    constructor(private httpClientWrapperService: HttpClientWrapperService) {
    }

    findPublicResume(resumeId: string, universityId?: string): Observable<any> {
        const requestParam = this.createRequestParam(resumeId, universityId);
        return this.httpClientWrapperService.get<any>(`${this.publicApiUrl}${this.resumeUrl}${this.searchUrl}`, requestParam);
    }

    findResumeByCandidateId(talentId: string) {
        return this.httpClientWrapperService.get<any>(`${this.publicApiUrl}${this.talentUrl}/${talentId}`);
    }

    patch(resumeId: string, resumePatchInDto: ResumePatchInDto): Observable<ResumeOutDto> {
        return this.httpClientWrapperService.patch<ResumeOutDto>(`${this.publicApiUrl}${this.resumeUrl}/${resumeId}`, resumePatchInDto);
    }

    private createRequestParam(resumeId: string, universityId: string) {
        const requestParam: RequestParam[] = [];
        requestParam.push({key: 'urlHandle', value: resumeId});
        if (universityId) {
            requestParam.push({key: 'universityId', value: universityId});
        }
        return requestParam;
    }
}

export class ResumePatchInDto {
    name?: string;
    title?: string;
    isDefault?: boolean;
    template?: RESUME_TEMPLATE_TYPE;
    theme?: RESUME_THEME_TYPE;
    atsLayout?: ATS_LAYOUT_TYPE;
    urlHandle?: string;
    removeBranding?: boolean;
    layoutType?: LAYOUT_TYPE;
}
