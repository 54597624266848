@if (resumeHasSummaryOutDto?.summary?.length > 0) {
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.atsLayout == ATS_LAYOUT_TYPE.CLASSIC ? classicLayoutRef : modernLayoutTemplateRef">
    </ng-container>
}

<ng-template #classicLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex flex-row items-center justify-start resume-section-title-bottom mt-6">
            <div class="resume-section-title"
                 [style.color]="resumeTheme?.primaryColor">
                {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY?.SUMMARY | titlecase }}
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="flex items-center justify-start resume-section-content-between"
             [style.color]="resumeTheme?.primaryColor">
            <div class="resume-section-container-description"
                 [ngStyle]="{'color': resumeTheme?.primaryColor}"
                 [innerHTML]="resumeHasSummaryOutDto?.summary">
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modernLayoutTemplateRef>
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.layoutType === LAYOUT_TYPE.TWO_COLUMN ? twoColumnModernLayoutRef : oneColumnModernLayoutRef">
    </ng-container>
</ng-template>

<ng-template #oneColumnModernLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex flex-row items-center justify-start resume-section-title-bottom mt-6">
            <i class="text-sm sm:text-2xl"
               [ngClass]="resumeIcon?.summary"
               [style.color]="resumeTheme?.iconColor">
            </i>
            <div class="resume-section-title pl-1 sm:pl-2"
                 [style.color]="resumeTheme?.primaryColor">
                {{ (resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY?.SUMMARY) | titlecase }}
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="flex items-center justify-start resume-section-content-between"
             [style.color]="resumeTheme?.primaryColor">
            <div class="resume-section-container-description"
                 [ngStyle]="{'color': resumeTheme?.primaryColor}"
                 [innerHTML]="resumeHasSummaryOutDto?.summary">
            </div>
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRef>
    <ng-container
        [ngTemplateOutlet]="resumeSectionDataOutDto?.columnIndex === 1 ? twoColumnModernLayoutRightRef :
        twoColumnModernLayoutLeftRef">
    </ng-container>
</ng-template>

<ng-template #twoColumnModernLayoutRightRef>
    <div class="px-4">
        <div class="section-title-right flex flex-row items-center justify-start resume-section-title-bottom mt-6">
            <i class="text-sm sm:text-2xl"
               [ngClass]="resumeIcon?.summary"
               [style.color]="resumeTheme?.iconColor">
            </i>
            <div class="resume-section-title pl-1 sm:pl-2"
                 [style.color]="resumeTheme?.primaryColor">
                {{ (resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY?.SUMMARY) | titlecase }}
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor "
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="flex items-center justify-start resume-section-content-between"
             [style.color]="resumeTheme?.primaryColor">
            <div class="resume-section-container-description"
                 [ngStyle]="{'color': resumeTheme?.primaryColor}"
                 [innerHTML]="resumeHasSummaryOutDto?.summary">
            </div>
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutLeftRef>
    <div class="px-4">
        <div class="section-title-right flex flex-row items-center justify-start resume-section-title-bottom mt-6">
            <i class="text-sm sm:text-2xl"
               [ngClass]="resumeIcon?.summary"
               [style.color]="resumeTheme?.iconColor">
            </i>
            <div class="resume-section-title pl-1 sm:pl-2"
                 [style.color]="resumeTheme?.primaryColor">
                {{ (resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY?.SUMMARY) | titlecase }}
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="flex items-center justify-start resume-section-content-between"
             [style.color]="resumeTheme?.primaryColor">
            <div class="resume-section-container-description"
                 [ngStyle]="{'color': resumeTheme?.primaryColor}"
                 [innerHTML]="resumeHasSummaryOutDto?.summary">
            </div>
        </div>
    </div>
</ng-template>
