@if (resumeHasHobbyOutDtos?.length > 0) {
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.atsLayout == ATS_LAYOUT_TYPE.CLASSIC ? classicLayoutRef : modernLayoutTemplateRef">
    </ng-container>
}

<ng-template #classicLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex-row justify-start items-center resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="resume-section-title"
                     [style.color]="resumeTheme.primaryColor">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.HOBBIES | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="w-full grid grid-cols-2 gap-2 sm:grid-cols-3">
            @for (resumeHasHobbyOutDto of resumeHasHobbyOutDtos; let index = $index; track resumeHasHobbyOutDto?.id) {
                <div class="h-full flex flex-col items-start justify-start section-card">
                    <div class="resume-section-container-title"
                         [style.color]="resumeTheme.primaryColor">
                        {{ resumeHasHobbyOutDto?.hobby }}
                    </div>
                    <div class="resume-section-container-text"
                         [ngClass]="resumeHasHobbyOutDto?.hobbyFrequency"
                         [style.color]="resumeTheme.secondaryColor">
                        {{ HOBBY_FREQUENCY_TYPE_DISPLAY[resumeHasHobbyOutDto?.hobbyFrequency] }}
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #modernLayoutTemplateRef>
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.layoutType === LAYOUT_TYPE.TWO_COLUMN ? twoColumnModernLayoutRef : oneColumnModernLayoutRef">
    </ng-container>
</ng-template>

<ng-template #oneColumnModernLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex-row justify-start items-center resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="ti ti-headphones text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme.iconColor}"></div>
                <div class="resume-section-title pl-1"
                     [style.color]=" resumeTheme.primaryColor ">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.HOBBIES | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="w-full grid grid-cols-2 gap-2 sm:grid-cols-3">
            @for (resumeHasHobbyOutDto of resumeHasHobbyOutDtos; let index = $index; track resumeHasHobbyOutDto?.id) {
                <div class="h-full flex flex-col items-start justify-start section-card">
                    <div class="resume-section-container-title"
                         [style.color]="resumeTheme.primaryColor">
                        {{ resumeHasHobbyOutDto?.hobby }}
                    </div>
                    <div class="resume-section-container-text"
                         [ngClass]="resumeHasHobbyOutDto?.hobbyFrequency"
                         [style.color]="resumeTheme.secondaryColor">
                        {{ HOBBY_FREQUENCY_TYPE_DISPLAY[resumeHasHobbyOutDto?.hobbyFrequency] }}
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRef>
    <ng-container
        [ngTemplateOutlet]="resumeSectionDataOutDto?.columnIndex === 1 ? twoColumnModernLayoutRightRef :
        twoColumnModernLayoutLeftRef">
    </ng-container>
</ng-template>

<ng-template #twoColumnModernLayoutRightRef>
    <div class="px-4">
        <div class="section-title-right flex-row justify-start items-center resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="ti ti-headphones text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme.iconColor}"></div>
                <div class="resume-section-title pl-1"
                     [style.color]=" resumeTheme.primaryColor ">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.HOBBIES | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="w-full grid grid-cols-2 gap-2 sm:grid-cols-3">
            @for (resumeHasHobbyOutDto of resumeHasHobbyOutDtos; let index = $index; track resumeHasHobbyOutDto?.id) {
                <div class="h-full flex flex-col items-start justify-start section-card">
                    <div class="resume-section-container-title"
                         [style.color]="resumeTheme.primaryColor">
                        {{ resumeHasHobbyOutDto?.hobby }}
                    </div>
                    <div class="resume-section-container-text"
                         [ngClass]="resumeHasHobbyOutDto?.hobbyFrequency"
                         [style.color]="resumeTheme.secondaryColor">
                        {{ HOBBY_FREQUENCY_TYPE_DISPLAY[resumeHasHobbyOutDto?.hobbyFrequency] }}
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutLeftRef>
    <div class="px-4">
        <div class="section-title-right flex-row justify-start items-center resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="ti ti-headphones text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme.iconColor}"></div>
                <div class="resume-section-title pl-1"
                     [style.color]="resumeTheme.twoColumnHighlightedHeadingText">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.HOBBIES | titlecase }}
                </div>
            </div>
        </div>
        <hr [style.border-color]="resumeTheme.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
        <div class="w-full grid grid-cols-1">
            @for (resumeHasHobbyOutDto of resumeHasHobbyOutDtos; let index = $index; track resumeHasHobbyOutDto?.id) {
                <div class="resume-section-content-between">
                    <div class="h-full flex flex-col items-start justify-start">
                        <div class="resume-section-container-title"
                             [style.color]="resumeTheme.twoColumnHighlightedPrimaryText">
                            {{ resumeHasHobbyOutDto?.hobby }}
                        </div>
                        <div class="resume-section-container-text"
                             [ngClass]="resumeHasHobbyOutDto?.hobbyFrequency"
                             [style.color]="resumeTheme.twoColumnHighlightedSecondaryText">
                            {{ HOBBY_FREQUENCY_TYPE_DISPLAY[resumeHasHobbyOutDto?.hobbyFrequency] }}
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>
