@if (resumeHasAchievementOutDtos?.length > 0) {
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.atsLayout == ATS_LAYOUT_TYPE.CLASSIC ? classicLayoutRef : modernLayoutTemplateRef">
    </ng-container>
}

<ng-template #classicLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="font-semibold resume-section-title flex flex-row items-center"
                     [ngStyle]="{'color': resumeThemeModel.primaryColor}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.ACHIEVEMENT | titlecase }}
                </div>
            </div>
        </div>

        <hr [style.border-color]="resumeThemeModel?.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>

        <div class="section-description">
            @for (resumeHasAchievementOutDto of resumeHasAchievementOutDtos; let index = $index; track index) {
                <div
                    class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                    <div class="w-full">
                        <div class="flex items-center justify-between">
                            <div class="resume-section-container-title"
                                 [ngStyle]="{'color': resumeThemeModel?.primaryColor}">
                                {{ resumeHasAchievementOutDto?.title }}
                            </div>
                        </div>
                        <div class="resume-section-container-text flex items-center justify-start"
                             [ngStyle]="{'color': resumeThemeModel?.secondaryColor}">
                            <div>{{ resumeHasAchievementOutDto?.region | titlecase }}</div>
                        </div>
                        @if (resumeHasAchievementOutDto?.description) {
                            <div class="resume-section-container-description"
                                 [ngStyle]="{'color': resumeThemeModel?.primaryColor}"
                                 [innerHTML]="resumeHasAchievementOutDto?.description"></div>
                        }
                        @if (resumeHasAchievementOutDto?.link) {
                            <div class="flex items-center justify-start">
                                <div class="cursor-pointer resume-section-container-text"
                                     [ngStyle]="{'color': resumeThemeModel?.iconColor}"
                                     (click)="openLinkInNewTab(resumeHasAchievementOutDto?.link)">
                                    <div class="color-primary">
                                        Link
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #modernLayoutTemplateRef>
    <ng-container
        [ngTemplateOutlet]="resumeOutDto?.layoutType === LAYOUT_TYPE.TWO_COLUMN ? twoColumnModernLayoutRef : oneColumnModernLayoutRef">
    </ng-container>
</ng-template>

<ng-template #oneColumnModernLayoutRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="text-sm sm:text-2xl"
                     [ngClass]="ResumeIconModel.getIconForResume().achievement"
                     [ngStyle]="{'color': resumeThemeModel.iconColor}">
                </div>
                <div class="font-semibold resume-section-title flex flex-row items-center pl-1"
                     [ngStyle]="{'color': resumeThemeModel.primaryColor}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.ACHIEVEMENT | titlecase }}
                </div>
            </div>
        </div>

        <hr [style.border-color]="resumeThemeModel.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>

        <div class="section-description">
            @for (resumeHasAchievementOutDto of resumeHasAchievementOutDtos; let index = $index; track index) {
                <div
                    class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                    <div class="w-full">
                        <div class="flex items-center justify-between">
                            <div class="resume-section-container-title"
                                 [ngStyle]="{'color': resumeThemeModel?.primaryColor}">
                                {{ resumeHasAchievementOutDto?.title }}
                            </div>
                        </div>
                        <div class="resume-section-container-text flex items-center justify-start"
                             [ngStyle]="{'color': resumeThemeModel?.secondaryColor}">
                            <div>{{ resumeHasAchievementOutDto?.region | titlecase }}</div>
                        </div>
                        @if (resumeHasAchievementOutDto?.description) {
                            <div class="resume-section-container-description"
                                 [ngStyle]="{'color': resumeThemeModel?.primaryColor}"
                                 [innerHTML]="resumeHasAchievementOutDto?.description"></div>
                        }
                        @if (resumeHasAchievementOutDto?.link) {
                            <div class="flex items-center justify-start">
                                <div class="text-xs sm:text-lg rotate-45"
                                     [ngClass]="ResumeIconModel.getIconForResume().link"
                                     [ngStyle]="{'color': resumeThemeModel?.iconColor}">
                                </div>
                                <div class="cursor-pointer resume-section-container-text pl-1 sm:pl-2"
                                     [ngStyle]="{'color': resumeThemeModel?.iconColor}"
                                     (click)="openLinkInNewTab(resumeHasAchievementOutDto?.link)">
                                    Achievement link
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutRef>
    <ng-container
        [ngTemplateOutlet]="resumeSectionDataOutDto?.columnIndex === 1 ? twoColumnModernLayoutRightRef :
        twoColumnModernLayoutLeftRef">
    </ng-container>
</ng-template>

<ng-template #twoColumnModernLayoutRightRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="text-sm sm:text-2xl"
                     [ngClass]="ResumeIconModel.getIconForResume().achievement"
                     [ngStyle]="{'color': resumeThemeModel.iconColor}">
                </div>
                <div class="font-semibold resume-section-title flex flex-row items-center pl-1"
                     [ngStyle]="{'color': resumeThemeModel.primaryColor}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.ACHIEVEMENT | titlecase }}
                </div>
            </div>
        </div>

        <hr [style.border-color]="resumeThemeModel.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>

        <div class="section-description">
            @for (resumeHasAchievementOutDto of resumeHasAchievementOutDtos; let index = $index; track index) {
                <div
                    class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                    <div class="w-full">
                        <div class="flex items-center justify-between">
                            <div class="resume-section-container-title"
                                 [ngStyle]="{'color': resumeThemeModel?.primaryColor}">
                                {{ resumeHasAchievementOutDto?.title }}
                            </div>
                        </div>
                        <div class="resume-section-container-text flex items-center justify-start"
                             [ngStyle]="{'color': resumeThemeModel?.secondaryColor}">
                            <div>{{ resumeHasAchievementOutDto?.region | titlecase }}</div>
                        </div>
                        @if (resumeHasAchievementOutDto?.description) {
                            <div class="resume-section-container-description"
                                 [ngStyle]="{'color': resumeThemeModel?.primaryColor}"
                                 [innerHTML]="resumeHasAchievementOutDto?.description"></div>
                        }
                        @if (resumeHasAchievementOutDto?.link) {
                            <div class="flex items-center justify-start">
                                <div class="text-xs sm:text-lg rotate-45"
                                     [ngClass]="ResumeIconModel.getIconForResume().link"
                                     [ngStyle]="{'color': resumeThemeModel?.iconColor}">
                                </div>
                                <div class="cursor-pointer resume-section-container-text pl-1 sm:pl-2"
                                     [ngStyle]="{'color': resumeThemeModel?.iconColor}"
                                     (click)="openLinkInNewTab(resumeHasAchievementOutDto?.link)">
                                    Achievement link
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #twoColumnModernLayoutLeftRef>
    <div class="px-4">
        <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
            <div class="flex items-center justify-start">
                <div class="text-sm sm:text-2xl"
                     [ngClass]="ResumeIconModel.getIconForResume().achievement"
                     [ngStyle]="{'color': resumeThemeModel.iconColor}">
                </div>
                <div class="font-semibold resume-section-title flex flex-row items-center pl-1"
                     [ngStyle]="{'color': resumeThemeModel.twoColumnHighlightedHeadingText}">
                    {{ resumeSectionDataOutDto?.title || RESUME_SECTION_TYPE_DISPLAY.ACHIEVEMENT | titlecase }}
                </div>
            </div>
        </div>

        <hr [style.border-color]="resumeThemeModel.iconColor"
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>

        <div class="section-description">
            @for (resumeHasAchievementOutDto of resumeHasAchievementOutDtos; let index = $index; track index) {
                <div class="w-full">
                    <div class="resume-section-container-title"
                         [ngStyle]="{'color': resumeThemeModel?.twoColumnHighlightedHeadingText}">
                        {{ resumeHasAchievementOutDto?.title }}
                    </div>
                    <div class="resume-section-container-text flex items-center justify-start"
                         [ngStyle]="{'color': resumeThemeModel?.secondaryColor}">
                        <div>{{ resumeHasAchievementOutDto?.region | titlecase }}</div>
                    </div>
                    @if (resumeHasAchievementOutDto?.description) {
                        <div class="resume-section-container-description"
                             [ngStyle]="{'color': resumeThemeModel?.twoColumnHighlightedHeadingText}"
                             [innerHTML]="resumeHasAchievementOutDto?.description"></div>
                    }
                    @if (resumeHasAchievementOutDto?.link) {
                        <div class="flex items-center justify-start">
                            <div class="text-xs sm:text-lg rotate-45"
                                 [ngClass]="ResumeIconModel.getIconForResume().link"
                                 [ngStyle]="{'color': resumeThemeModel?.iconColor}">
                            </div>
                            <div class="cursor-pointer resume-section-container-text pl-1 sm:pl-2"
                                 [ngStyle]="{'color': resumeThemeModel?.iconColor}"
                                 (click)="openLinkInNewTab(resumeHasAchievementOutDto?.link)">
                                Achievement link
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</ng-template>

