import {Component, Input, OnInit} from '@angular/core';
import {ResumeHasSocialLinkOutDto} from '../../common/resume-section-model/social-link.model';
import {ResumeThemeModel} from '../../common/theme/resume-theme.model';
import {SOCIAL_LINK} from '../../common/social-link.model';
import {RESUME_ICON_LIST} from '../../common/resume-section-model/resume.model';
import {RESUME_SECTION_TYPE_DISPLAY} from '../../common/resume-section.model';
import {SOCIAL_LINK_DISPLAY} from '../../../website/common/website-model/social-link-out.dto';
import {LinkService} from '../../../../common/util/link.service';
import {SelectThemeUtil} from "../../common/select-theme.util";
import {ResumeStore} from "../../../ai-resume/common/store/resume.store";
import {NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {ATS_LAYOUT_TYPE, LAYOUT_TYPE} from "../../common/layout-selection.model";
import {ResumeOutDto, ResumeSectionDataOutDto} from "../../common/resume.model";
import {Subscription} from "rxjs";

@Component({
    selector: 'tal-r-social-link',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NgClass,
        NgStyle,
        TitleCasePipe
    ],
    templateUrl: './r-social-link.component.html',
    styleUrl: './r-social-link.component.scss'
})
export class RSocialLinkComponent implements OnInit {
    @Input() resumeSectionDataOutDto: ResumeSectionDataOutDto;
    resumeOutDto?: ResumeOutDto;
    resumeTheme?: ResumeThemeModel;
    socialLinks: ResumeHasSocialLinkOutDto[];

    SOCIAL_LINK = SOCIAL_LINK;
    RESUME_ICON_LIST = RESUME_ICON_LIST;
    protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
    protected readonly SOCIAL_LINK_DISPLAY = SOCIAL_LINK_DISPLAY;
    protected readonly ATS_LAYOUT_TYPE = ATS_LAYOUT_TYPE;
    protected readonly LAYOUT_TYPE = LAYOUT_TYPE;

    private subscription = new Subscription();

    constructor(private resumeStore: ResumeStore,
                private linkService: LinkService) {
    }

    ngOnInit(): void {
        this.subscribeToResumeStore();
    }

    openUrlInNewTab(name: string): void {
        this.linkService.openLinkInNewTab(name);
    }

    private subscribeToResumeStore(): void {
        this.subscription.add(this.resumeStore.updatedResumeOutDto
            .subscribe(resumeOutDto => {
                if (resumeOutDto) {
                    this.resumeOutDto = resumeOutDto;
                    this.socialLinks = resumeOutDto?.socialLinks;
                    this.setResumeTheme();
                }
            }));
    }

    private setResumeTheme(): void {
        this.resumeTheme = SelectThemeUtil.setResumeTheme(this.resumeOutDto.atsLayout === ATS_LAYOUT_TYPE.CLASSIC, this.resumeOutDto?.layoutType, this.resumeOutDto?.theme);
    }
}
