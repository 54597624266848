import {Component, Input, OnInit} from '@angular/core';
import {CONTACT_NUMBER_TYPE_ICON, ResumeIconModel} from "../../common/resume-icon.model";
import {ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {ResumeHasPersonalInformationOutDto} from "../../common/resume-section-model/personal-information.model";
import {ATS_LAYOUT_TYPE, LAYOUT_TYPE} from "../../common/layout-selection.model";
import {SelectThemeUtil} from "../../common/select-theme.util";
import {ResumeStore} from "../../../ai-resume/common/store/resume.store";
import {NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {ResumeOutDto, ResumeSectionDataOutDto} from "../../common/resume.model";
import {Subscription} from "rxjs";
import {RSocialLinkComponent} from "../r-social-link/r-social-link.component";


@Component({
    selector: 'tal-r-personal-information',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NgClass,
        TitleCasePipe,
        NgStyle,
        RSocialLinkComponent
    ],
    templateUrl: './r-personal-information.component.html',
    styleUrl: './r-personal-information.component.scss'
})
export class RPersonalInformationComponent implements OnInit {

    @Input() resumeSectionDataOutDto: ResumeSectionDataOutDto;
    resumeOutDto?: ResumeOutDto;
    resumeTheme?: ResumeThemeModel;
    resumeHasPersonalInformationOutDto: ResumeHasPersonalInformationOutDto;

    resumeIcon = ResumeIconModel.getIconForResume();
    LAYOUT_TYPE = LAYOUT_TYPE;
    protected readonly CONTACT_NUMBER_TYPE_ICON = CONTACT_NUMBER_TYPE_ICON;
    protected readonly ATS_LAYOUT_TYPE = ATS_LAYOUT_TYPE;
    private subscription = new Subscription();

    constructor(private resumeStore: ResumeStore) {
    }

    ngOnInit(): void {
        this.subscribeToResumeStore();
    }

    private subscribeToResumeStore(): void {
        this.subscription.add(this.resumeStore.updatedResumeOutDto
            .subscribe(resumeOutDto => {
                if (resumeOutDto) {
                    this.resumeOutDto = resumeOutDto;
                    this.resumeHasPersonalInformationOutDto = resumeOutDto?.personalInformation;
                    this.setResumeTheme();
                }
            }));
    }

    private setResumeTheme(): void {
        this.resumeTheme = SelectThemeUtil.setResumeTheme(this.resumeOutDto.atsLayout === ATS_LAYOUT_TYPE.CLASSIC, this.resumeOutDto?.layoutType, this.resumeOutDto?.theme);
    }
}
